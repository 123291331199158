import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Col, CustomInput, Row } from "reactstrap";
import { useService } from "../../context/ServiceContext";
import Spinner from "../Spinner";

function IconSelector({ icon, availableIcons, template, productData, updateTemplate }) {
  function selectIcon(nombreFichero) {
    updateTemplate({
      ...template,
      icons: template.icons.map((i) => {
        if (i.position === icon.position) {
          return { ...i, value: nombreFichero };
        } else return i;
      }),
    });
  }

  function setBackground(image) {
    return image && image !== "empty"
      ? {
          backgroundImage: `url(https://easyventtest.solerpalau.com/images/icons/${image}?width=150)`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: 35,
          height: 35,
        }
      : undefined;
  }

  const selectedIcon = template.icons.find((i) => i.position === icon.position);

  return (
    <>
      <h4>{icon.referenciaDocumento}</h4>
      <div className="d-flex mb-3">
        {!availableIcons ? (
          <Spinner></Spinner>
        ) : (
          availableIcons.map((availableIcon, i) => (
            <Button
              key={i}
              outline
              color={selectedIcon?.value == availableIcon.nombreFichero ? "primary" : "light"}
              onClick={(e) => {
                selectIcon(availableIcon.nombreFichero);
              }}
            >
              {!availableIcon.nombreFichero ? <i className="fas fa-times"></i> : <div style={setBackground(availableIcon.nombreFichero)}></div>}
            </Button>
          ))
        )}
      </div>
    </>
  );
}

export default IconSelector;
