import React, { useEffect } from "react";
import { ValidationForm } from "react-bootstrap4-form-validation";
import { FormattedMessage } from "react-intl";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, CustomInput, FormGroup, Label } from "reactstrap";

function FrequenciesStep({ next, prev, availableFrequencies, frequencies, setFrequencies }) {
  useEffect(() => {
    if (availableFrequencies?.length === 1) {
      setFrequencies([availableFrequencies[0].id]);
    }
  }, []);

  function updateFrequency(id, state) {
    setFrequencies(state ? (frequencies.includes((f) => f === id) ? [...frequencies] : frequencies.concat(id)) : frequencies.filter((f) => f !== id));
  }

  return (
    <ValidationForm onSubmit={() => {}}>
      <Card className="mt-2">
        <CardHeader>
          <FormattedMessage id="product_wizard.frequencies" defaultMessage="Frequencies" />
        </CardHeader>
        <CardBody>
          <p>
            <FormattedMessage
              id="product_wizard.choose_all_frequencies"
              defaultMessage="Choose all frequencies that you will need to use to define this particular group of products:"
            />
          </p>
          {availableFrequencies.map((f) => (
            <FormGroup row key={f.id}>
              <Label sm={3}>{f.frequency}Hz</Label>
              <Col sm={3}>
                <CustomInput type="switch" id={`frequency-${f.id}`} checked={frequencies.includes(f.id)} onChange={(e) => updateFrequency(f.id, e.target.checked)} />
              </Col>
            </FormGroup>
          ))}
        </CardBody>
        <CardFooter>
          <Button className="btn btn-outline-dark float-right" onClick={next}>
            <FormattedMessage id="app.next" defaultMessage="Next" />
          </Button>
          <Button className="btn btn-outline-danger" onClick={prev}>
            <i className="fas fa-arrow-left"></i> <FormattedMessage id="app.back" defaultMessage="Back" />
          </Button>
        </CardFooter>
      </Card>
    </ValidationForm>
  );
}

export default FrequenciesStep;
