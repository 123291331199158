import React from "react";
import { Row, Col, Button } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import SubmitButton from "../SubmitButton";

function CurveViewerHeader(props) {
  const { onSave, onReset, isSaved, isSaving, curveSeries, curveGroup, curveName, laboratoryTestReference, category, id, readOnly } = props;

  async function handleSaveClick(e) {
    e.preventDefault();
    await onSave();
  }

  return (
    <>
      <div className="d-flex">
        <Row className="flex-grow-1 align-self-center">
          <Col className="col-auto">
            <span className="mr-3">
              <b>
                <FormattedMessage id="curves_generator.curve_series" defaultMessage="Curve series" />
              </b>
            </span>
            <span>{curveSeries}</span>
          </Col>
          <Col className="col-auto">
            <span className="mr-3">
              <b>
                <FormattedMessage id="curves_generator.curve_group" defaultMessage="Curve group" />
              </b>
            </span>
            <span>{curveGroup ?? "-"}</span>
          </Col>
          <Col className="col-auto">
            <span className="mr-3">
              <b>
                <FormattedMessage id="curves_generator.curve" defaultMessage="Curve" />
              </b>
            </span>
            <span>{curveName}</span>
          </Col>

          {category != "G16" && (
            <>
              <Col className="col-auto">
                <span className="mr-3">
                  <b>
                    <FormattedMessage id="curves_generator.lab_test_number" defaultMessage="Lab test number" />
                  </b>
                </span>
                <span>{laboratoryTestReference}</span>
              </Col>
            </>
          )}
          {id && (
            <Col className="col-auto">
              <span className="mr-3">
                <b>
                  <FormattedMessage id="curves_generator.curve_test_id" defaultMessage="ID ensayo" />
                </b>
              </span>
              <span>{id.value}</span>
            </Col>
          )}
        </Row>
        <div className="flex-shrink-0">
          <Button className="btn btn-outline-dark" onClick={onReset} disabled={isSaved}>
            <i className="fas fa-undo"></i> <FormattedMessage id="curves_generator.reset" defaultMessage="Reset" />
          </Button>
          {!readOnly && (
            <SubmitButton className="btn btn-outline-primary" onClick={handleSaveClick} submitting={isSaving ? 1 : 0} submitted={isSaved ? 1 : 0} disabled={readOnly}></SubmitButton>
          )}
        </div>
      </div>
    </>
  );
}

export default withRouter(CurveViewerHeader);
