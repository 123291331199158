import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Col, Form, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import SubmitButton from "../../SubmitButton";
import AlertWithIcon from "../../AlertWithIcon";
import DependentAcousticTestForm from "../../DependentAcousticTestForm";
import LoadedContent from "../../LoadedContent";

function DependentAcousticTestGeneratorModal({ isOpen, toggle, onChange, onSubmit, dependent, alert }) {
  const [test, setTest] = useState({
    reasonForChange: { reason: null, subCurves: [] },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittable, setIsSubmittable] = useState(false);

  function updateTest(_, attribute, value) {
    setTest({ ...test, [attribute]: value });
    onChange && onChange(test);
  }

  async function handleFormSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);
    onSubmit && (await onSubmit(test.reasonForChange));
    setIsSubmitting(false);
  }

  function renderSubmitButtonIcon({ submitting, submitted }) {
    if (submitting) {
      return <i className="fas fa-sync fa-pulse"></i>;
    } else if (submitted) {
      return <i className="fas fa-check"></i>;
    } else {
      return <i className="fas fa-sync"></i>;
    }
  }

  function renderSubmitButtonText({ submitting, submitted }) {
    if (submitting) {
      return <FormattedMessage id="app.generating" defaultMessage="Generating..." />;
    } else if (submitted) {
      return <FormattedMessage id="app.generated" defaultMessage="Generated" />;
    } else {
      return <FormattedMessage id="app.generate" defaultMessage="Generate" />;
    }
  }

  function renderSubmitButtonChild(state) {
    return (
      <>
        {renderSubmitButtonIcon(state)} {renderSubmitButtonText(state)}
      </>
    );
  }

  useEffect(() => {
    const subCurves = [...test.reasonForChange.subCurves];
    setIsSubmittable(subCurves?.length > 0);
  }, [test]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size={"lg"} className={""}>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id="curves_generator.generate_dependent_acoustic_test" defaultMessage="Generate dependent acoustic test" />
      </ModalHeader>
      <ModalBody>
        <Form type="submit" onSubmit={handleFormSubmit}>
          <LoadedContent loading={isSubmitting} overlay={true}>
            <DependentAcousticTestForm updateTest={updateTest} test={test} />
          </LoadedContent>

          <Row>
            <Col xs={12} className="mt-3">
              {alert && (
                <AlertWithIcon className="alert-danger" icon={<i className="fas fa-exclamation-circle"></i>}>
                  {alert}
                </AlertWithIcon>
              )}
            </Col>

            <Col xs={12} className="mt-2">
              {/* {dependent && <a href={`/curves-generator/viewer/${dependent.value}/summary`} className="btn btn-success btn-md float-left">
                                <i className="fas fa-eye"></i> <FormattedMessage id="curves_generator.dependent.view_generated_test" defaultMessage="View generated test" />
                            </a>} */}
              <SubmitButton type="submit" className="btn-outline-primary btn-md float-right" submitting={isSubmitting ? 1 : 0} submitted={!!dependent ? 1 : 0} disabled={!isSubmittable}>
                {renderSubmitButtonChild}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default DependentAcousticTestGeneratorModal;
