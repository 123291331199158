import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import ListerTable from "../../Tables/ListerTable";

function renderSmallTableHeader({ column, children }) {
  return (
    <th {...column.getHeaderProps()} style={{ width: "1%" }}>
      {children}
    </th>
  );
}

function SeriesStep({ serieComercialLister, setSeries, next }) {
  const intl = useIntl();

  return (
    <Card className="mt-2">
      <CardHeader>
        <FormattedMessage id="product_wizard.select_a_range" defaultMessage="Select a range" />
      </CardHeader>
      <CardBody>
        <p>
          <FormattedMessage id="product_wizard.please_select_existing_range" defaultMessage="Please, select an existing range" />
        </p>
        <ListerTable
          className={"align-items-center table-flush"}
          columns={[
            { Header: intl.formatMessage({ id: "product_wizard.series_name", defaultMessage: "Series name" }), accessor: "name" },
            //{ Header: intl.formatMessage({ id: "product_wizard.tipo_curva", defaultMessage: "Tipo Curva" }), accessor: "tipoCurva" },
            {
              id: "select",
              TableHeader: renderSmallTableHeader,
              Cell: ({ cell }) => (
                <Button
                  className="btn btn-outline-danger float-right"
                  onClick={() => {
                    setSeries(cell.row.original);
                    next();
                  }}
                >
                  {intl.formatMessage({
                    id: "app.select",
                    defaultMessage: "Select",
                  })}
                </Button>
              ),
            },
          ]}
          lister={serieComercialLister}
          prefilter={{ name: " " }}
        />
      </CardBody>
    </Card>
  );
}

export default SeriesStep;
