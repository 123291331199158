import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody, Input, Button } from "reactstrap";
import Header from "../../components/Header";
import { FormattedMessage, useIntl } from "react-intl";
import { useService } from "../../context/ServiceContext";
import ListerTableSelectionModal from "../../components/Modals/ListerTableSelectionModal";
import CurveSearchComponent from "../../components/CurvesGenerator/CurveSearch";
import { useConfig } from "../../context/ConfigContext";

function CurveSearch(props) {
  const intl = useIntl();
  const { aeroTestLister } = useService();
  const limsRoot = useConfig("lims.root");
  const [labTestModalOpen, setLabTestModalOpen] = useState(false);
  const [enableLabTestCurveFilter, setEnableLabTestCurveFilter] = useState(true);
  const [selectedCurve, setSelectedCurve] = useState(null);

  const toggleLabTestModal = () => setLabTestModalOpen(!labTestModalOpen);

  function handleSelection(value) {
    setSelectedCurve(value);
    setEnableLabTestCurveFilter(true);
    toggleLabTestModal();
  }

  function handleNoModeloCurva() {
    setSelectedCurve({ id: 1 });
    setEnableLabTestCurveFilter(false);
    toggleLabTestModal();
  }

  function handleLabTestSelection(choice) {
    toggleLabTestModal();
    props.history.push(`/curves-generator/viewer/new/${selectedCurve.id}/${choice.id}/data`);
  }

  return (
    <>
      <Header>
        <h1>
          <FormattedMessage id="curves_generator.new_curve_test.title" defaultMessage="New idEnsayo" />
        </h1>
      </Header>
      <Container fluid>
        <Card>
          <CardBody>
            <Row className="mb-3">
              <Col>
                <h3 className="card-title">
                  <FormattedMessage id="curves_generator.search_curve.title" defaultMessage="Search for a modelo curva" />
                </h3>
                <p className="card-text">
                  <FormattedMessage id="curves_generator.search_curve.description" defaultMessage="To create a new idEnsayo, start by searching for an existing modelo curva." />
                </p>
              </Col>
            </Row>

            <CurveSearchComponent onCurveSelect={handleSelection} {...props} />

            <div className="section-break my-4">OR</div>

            <div className="text-center">
              <Button type="button" className="btn-outline-primary" onClick={e => handleNoModeloCurva()}><FormattedMessage id="curves_generator.proceed_without_modelo_curva" defaultMessage="Proceed without a modelo curva" /></Button>
            </div>
          </CardBody>
        </Card>

        <ListerTableSelectionModal
          title={intl.formatMessage({ id: "curves_generator.select_aerodynamic_test", defaultMessage: "Select an aerodynamic test" })}
          columns={[
            { Header: intl.formatMessage({ id: "tests.lab_name", defaultMessage: "Lab name" }), accessor: "laboratoryName" },
            { Header: intl.formatMessage({ id: "tests.lab_test_number", defaultMessage: "Lab test number" }), accessor: "testNumber" },
            { Header: intl.formatMessage({ id: "tests.request_number", defaultMessage: "Request number" }), accessor: "testRequestNumber" },
            { Header: intl.formatMessage({ id: "tests.brand", defaultMessage: "Brand" }), accessor: "brandName" },
            { Header: intl.formatMessage({ id: "tests.model", defaultMessage: "Model" }), accessor: "productModelName" },
            { Header: intl.formatMessage({ id: "tests.project_name", defaultMessage: "Project name" }), accessor: "projectName" },
            {
              id: "view",
              Cell: ({ cell }) => (
                <a className="btn btn-outline-dark" href={limsRoot + "/Reports/Aerodynamic/View/" + cell.row.original.id} target="_blank">
                  <FormattedMessage id="app.view" defaultMessage="View" />
                </a>
              ),
            },
          ]}
          prefilter={enableLabTestCurveFilter ? { curveId: selectedCurve?.id, productId: selectedCurve?.productId } : {}}
          isOpen={labTestModalOpen}
          toggle={toggleLabTestModal}
          lister={aeroTestLister}
          onSelect={handleLabTestSelection}
          renderFooter={() => <>
            <label className="custom-toggle mr-2">
              <Input type="checkbox" checked={enableLabTestCurveFilter} onChange={e => setEnableLabTestCurveFilter(e.target.checked)} />
              <span className="custom-toggle-slider rounded-circle" />
            </label>
            <FormattedMessage id="curves_generator.filter_lab_tests_by_curve" defaultMessage="Filter lab tests by associated curve" />
          </>}
        />
      </Container>
    </>
  );
}

export default CurveSearch;
