import React, { Fragment, useEffect, useState } from "react";
import { ValidationForm } from "react-bootstrap4-form-validation";
import { FormattedMessage } from "react-intl";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, CustomInput, FormGroup, Label } from "reactstrap";

function MotorPolesStep({ next, prev, availablePoles, poles, setPoles }) {
  const [filteredPoles, setFilteredPoles] = useState();

  useEffect(() => {
    if (availablePoles?.length === 1) {
      setPoles([availablePoles[0].id]);
    }

    var filtered = [];
    var previousPole = "";
    var filteredIndex = -1;
    for (let p = 0; p < availablePoles.length; p++) {
      const pole = availablePoles[p];
      const poleKey = pole.pole.substring(0, 1);
      if (previousPole != poleKey) {
        previousPole = poleKey;
        filteredIndex++;
        filtered[filteredIndex] = [];
      }
      filtered[filteredIndex].push(pole);
    }
    setFilteredPoles(filtered);
  }, [availablePoles]);

  function updatePoles(id, state) {
    setPoles(state ? (poles.includes((f) => f === id) ? [...poles] : poles.concat(id)) : poles.filter((f) => f !== id));
  }

  return (
    <ValidationForm onSubmit={() => {}}>
      <Card className="mt-2">
        <CardHeader>
          <FormattedMessage id="product_wizard.motor_poles" defaultMessage="Motor Poles" />
        </CardHeader>
        <CardBody>
          <p>
            <FormattedMessage id="product_wizard.choose_poles" defaultMessage="Choose all poles that you will need to use to define this particular group of products:" />
          </p>
          <div style={{ width: 400 }}>
            {filteredPoles &&
              filteredPoles.map((pk, p) => (
                <FormGroup row key={`poleKeys-${p}`}>
                  {pk.map((p, i) => (
                    <Fragment key={p.id}>
                      <Label sm={2}>{p.pole}</Label>
                      <Col sm={2}>
                        <CustomInput type="switch" id={`pole-${p.id}`} checked={poles.includes(p.id)} onChange={(e) => updatePoles(p.id, e.target.checked)} />
                      </Col>
                    </Fragment>
                  ))}
                </FormGroup>
              ))}
          </div>
        </CardBody>
        <CardFooter>
          <Button className="btn btn-outline-dark float-right" onClick={next}>
            <FormattedMessage id="app.next" defaultMessage="Next" />
          </Button>
          <Button className="btn btn-outline-danger" onClick={prev}>
            <i className="fas fa-arrow-left"></i> <FormattedMessage id="app.back" defaultMessage="Back" />
          </Button>
        </CardFooter>
      </Card>
    </ValidationForm>
  );
}

export default MotorPolesStep;
