import React from "react";
import { ValidationForm } from "react-bootstrap4-form-validation";
import { FormattedMessage } from "react-intl";
import { Button, Card, CardBody, CardFooter, CardHeader, CustomInput } from "reactstrap";
import CustomList from "./CustomList";

function CurveGroupDefinitionStep({ next, prev, availableFilterPrincipalMG, filterPrincipalMG, setFilterPrincipalMG, customFilterPrincipalMG, setCustomFilterPrincipalMG }) {
  return (
    <ValidationForm onSubmit={next}>
      <Card className="mt-2">
        <CardHeader>
          <FormattedMessage id="product_wizard.curves_groups_definition" defaultMessage="Curves groups definition" />
        </CardHeader>
        <CardBody>
          <p>
            <FormattedMessage id="product_wizard.choose_best_fit_option" defaultMessage="Choose the option which fits better with the products that you are adding:" />
          </p>
          <div style={{ display: "inline-block", minWidth: 800 }}>
            <CustomList
              list={availableFilterPrincipalMG}
              values={filterPrincipalMG}
              setValues={setFilterPrincipalMG}
              customValues={customFilterPrincipalMG}
              setCustomValues={setCustomFilterPrincipalMG}
              addButtonText="product_wizard.curves_personalized"
              examplePlaceholder="Example: ABC"
            ></CustomList>
          </div>
        </CardBody>
        <CardFooter>
          <Button className="btn btn-outline-dark float-right">
            <FormattedMessage id="app.next" defaultMessage="Next" />
          </Button>
          <Button className="btn btn-outline-danger" onClick={prev}>
            <i className="fas fa-arrow-left"></i> <FormattedMessage id="app.back" defaultMessage="Back" />
          </Button>
        </CardFooter>
      </Card>
    </ValidationForm>
  );
}

export default CurveGroupDefinitionStep;
