import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useProduct } from "../../context/ProductContext";
import { useService } from "../../context/ServiceContext";
import { fireAlert, fireConfirm } from "../../utils/alerts";
import LoadedContent from "../LoadedContent";
import Tooltip from "../Tooltip";
import ErrorSummary from "./ErrorSummary";
import ProductProgress from "./ProductProgress";

function ProductSummary({ productConceptsProvider, onPrevious, progressItems, history }) {
  const intl = useIntl();
  const { productSpecificationStore } = useService();
  const { product, products, updateProducts, isWizard, applyDefinitions, checkValidity, isNewProduct, productsAllValid } = useProduct();
  const [conceptClasses, setConceptClasses] = useState([]);
  const [saving, setSaving] = useState(false);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [showIdEnsayoButton, setShowIdEnsayoButton] = useState(false);

  useEffect(() => {
    applyDefinitions();
    getConcepts();
    checkSaveEnabled();
  }, []);

  async function checkSaveEnabled() {
    setSaveEnabled(await productsAllValid());
  }

  async function getConcepts() {
    setConceptClasses(await productConceptsProvider.get(-1));
  }

  function lookupConceptClass(conceptClassUnic) {
    if (conceptClasses.length == 0) return conceptClassUnic;
    return conceptClasses.find((c) => c.id == conceptClassUnic)?.value || conceptClassUnic;
  }

  async function submitProducts() {
    setSaving(true);

    try {
      const results = await productSpecificationStore.store(products);

      let clonedProducts = [...products];

      for (let i = 0; i < results.length; i++) {
        const result = results[i];
        let product = clonedProducts[i];
        product.result = result.result;

        if (result.codModeloProductoGenerico) {
          product.id = result.codModeloProductoGenerico;

          if (result.groupResults?.length > 0) {
            for (let g = 0; g < result.groupResults.length; g++) {
              const groupResult = result.groupResults[g];
              let curveGroup = product.curveGroups[g];

              if (groupResult.codModeloGrupoCurva > 0) {
                curveGroup.id = groupResult.codModeloGrupoCurva;

                if (groupResult.curveResults?.length > 0) {
                  for (let c = 0; c < groupResult.curveResults.length; c++) {
                    const curveResult = groupResult.curveResults[groupResult.curveResults.length - (c + 1)];
                    let curve = curveGroup.curves[c];
                    curve.id = curveResult;
                  }
                }
              }
            }
          }
        }
      }

      updateProducts(clonedProducts, true);

      setShowIdEnsayoButton(!results.some((r) => r.result == false));

      /* if (!results.some((r) => r.result == false)) {
        fireConfirm(
          intl.formatMessage({
            id: "product_summary.would_you_like_to_create_an_idensayo",
            defaultMessage: "Click here to start creating the idEnsayo's",
          }),
          intl.formatMessage({ id: "app.success", defaultMessage: "Success" }),
          "question",
          intl.formatMessage({ id: "app.yes", defaultMessage: "Yes" }),
          intl.formatMessage({ id: "app.no", defaultMessage: "No" }),
          () => {
            history.push(`/curves-generator`);
          },
          () => {}
        );
      } */
    } catch (error) {}

    setSaving(false);
  }

  return (
    <LoadedContent loading={saving} overlay={true}>
      <Row>
        <Col xl={3} className="d-sm-none d-xl-block">
          <ProductProgress onPrevious={onPrevious} items={progressItems}></ProductProgress>
        </Col>
        <Col xl={9}>
          <Card>
            <CardHeader>
              <FormattedMessage id="product_information.finish" defaultMessage="Please check the summary below" />
            </CardHeader>
            <CardBody>
              <table className="table align-items-center">
                <thead className="thead-light">
                  <tr>
                    <th style={{ width: 80 }}>
                      <FormattedMessage id="product_information.column_status" defaultMessage="Status" />
                    </th>
                    <th>
                      <FormattedMessage id="product_information.column_modelo_producto" defaultMessage="Modelo Producto" />
                    </th>
                    <th>
                      <FormattedMessage id="product_information.column_series" defaultMessage="Series" />
                    </th>
                    <th>
                      <FormattedMessage id="product_information.column_company" defaultMessage="Company" />
                    </th>
                    <th>
                      <FormattedMessage id="product_information.column_concept_class" defaultMessage="Concept class" />
                    </th>
                    <th style={{ width: 120 }}></th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((p, i) => (
                    <SummaryRow
                      product={p}
                      isWizard={isWizard}
                      isNewProduct={isNewProduct}
                      index={i}
                      checkValidity={checkValidity}
                      lookupConceptClass={lookupConceptClass}
                      key={i}
                    ></SummaryRow>
                  ))}
                </tbody>
              </table>
            </CardBody>
            <CardFooter>
              {/* <button className="btn btn-danger" disabled={!saveEnabled} onClick={submitProducts}> */}
              <button className="btn btn-outline-danger" onClick={submitProducts}>
                <FormattedMessage id="app.save" defaultMessage="Save" />
              </button>
              {showIdEnsayoButton && (
                <button className="btn btn-outline-dark" onClick={() => history.push(`/curves-generator`)}>
                  <FormattedMessage id="product_summary.would_you_like_to_create_an_idensayo" defaultMessage="Click here to start creating the idEnsayo's" />
                </button>
              )}
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </LoadedContent>
  );
}

function SummaryRow({ product, isWizard, isNewProduct, checkValidity, lookupConceptClass, index }) {
  const [errors, setErrors] = useState(false);
  const [showGroups, setShowGroups] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    const check = async () => {
      const err = await checkValidity(product);
      setErrors(err);
    };
    check();
  }, [product]);

  return (
    <>
      <tr>
        <td>
          {!errors ? (
            <i className="fas fa-2x fa-circle-notch fa-spin"></i>
          ) : errors.hasErrors ? (
            <i
              className="text-danger fas fa-2x fa-minus-circle"
              onClick={() => fireAlert(<ErrorSummary intl={intl} errors={errors}></ErrorSummary>, "Incorrect attributes", "error")}
            ></i>
          ) : (
            <i className="text-success fas fa-2x fa-check-circle"></i>
          )}
        </td>
        <td>
          <b>{product.modeloProductoGenerico || `Product ${index + 1}`}</b>
        </td>
        <td>{product.series.name}</td>
        <td>{product.idCompanyManufacturer}</td>
        <td>{lookupConceptClass(product.conceptClassUnic)}</td>
        <td className="text-center">
          <Link
            to={
              isWizard
                ? `/products/wizard/${index}/technical-information`
                : isNewProduct
                ? `/products/product/new/-1/0/technical-information`
                : `/products/product/${product.id.value}/${product.series.id}/${product.id.version}/technical-information`
            }
            className="btn btn-sm btn-outline-danger"
          >
            <FormattedMessage id="app.edit" defaultMessage="Edit" />
          </Link>
        </td>
      </tr>
      {!showGroups ? (
        <tr>
          <td>
            <Tooltip text="Show groups summary">
              <button type="button" className="btn btn-sm btn-link text-dark" onClick={() => setShowGroups(true)}>
                <i className="fas fa-plus"></i>
              </button>
            </Tooltip>
          </td>
          <td colSpan="5">
            <FormattedMessage id="product_summary.number_of_groups" defaultMessage="Number of groups:" /> {product.curveGroups.length}
          </td>
        </tr>
      ) : (
        <tr>
          <td className="p-0" colSpan="6">
            <table className="table align-items-center mb-0">
              <thead>
                <tr>
                  <th style={{ width: 80 }}>
                    <Tooltip text="Hide groups summary">
                      <button type="button" className="btn btn-sm btn-link text-dark" onClick={() => setShowGroups(false)}>
                        <i className="fas fa-minus"></i>
                      </button>
                    </Tooltip>
                  </th>
                  <th>
                    <FormattedMessage id="product_summary.groups" defaultMessage="Groups" />
                  </th>
                  <th>
                    <FormattedMessage id="product_summary.modelo_dato" defaultMessage="Modelo Dato" />
                  </th>
                  <th>
                    <FormattedMessage id="product_summary.concept_class" defaultMessage="Concept class" />
                  </th>
                  <th className="text-center" style={{ width: 120 }}>
                    {/* Principal */}
                  </th>
                </tr>
              </thead>
              <tbody>
                {product.curveGroups.map((g, j) => (
                  <Fragment key={j}>
                    <tr>
                      <th className="p-0 bg-secondary"></th>
                      <th scope="row">
                        {j + 1}. {g.modeloGrupoCurva}
                      </th>
                      <td>{g.modeloDato}</td>
                      <td>{lookupConceptClass(g.conceptClassUnic)}</td>
                      <td className="text-center">
                        {/* {g.principal || product.curveGroups.length === 1 ? <i className="text-success fas fa-circle"></i> : <i className="far fa-circle"></i>} */}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-0" colSpan="5">
                        <table className="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th style={{ width: 160 }}></th>
                              <th>
                                <FormattedMessage id="product_summary.curves" defaultMessage="Curves" />
                              </th>
                              <th className="text-center" style={{ width: 120 }}>
                                <FormattedMessage id="product_summary.principal" defaultMessage="Principal" />
                              </th>
                            </tr>
                          </thead>
                          {g.curves.map((c, k) => (
                            <Fragment key={k}>
                              <tr>
                                <th className="p-0 bg-secondary"></th>
                                <th scope="row">
                                  {k + 1}. {c.modeloCurva}
                                </th>
                                <td className="text-center">
                                  {c.principal || g.curves.length === 1 ? <i className="text-success fas fa-circle"></i> : <i className="far fa-circle"></i>}
                                </td>
                              </tr>
                            </Fragment>
                          ))}
                        </table>
                      </td>
                    </tr>
                  </Fragment>
                ))}
              </tbody>
            </table>
          </td>
        </tr>
      )}
      {product.result !== undefined ? (
        <tr>
          <td className={`text-white text-center bg-${product.result ? "success" : "danger"}`} colSpan="6">
            {product.result ? (
              <>
                <i className="fas fa-check"></i> <FormattedMessage id="product_summary.product_saved_to_database" defaultMessage="Product saved to database" />
              </>
            ) : (
              <>
                <i className="fas fa-minus"></i> <FormattedMessage id="product_summary.product_save_error" defaultMessage="A problem occurred saving this product" />
              </>
            )}
          </td>
        </tr>
      ) : (
        <tr>
          <td className="p-0 bg-dark" style={{ height: 2 }} colSpan="6"></td>
        </tr>
      )}
    </>
  );
}

export default ProductSummary;
