import React from "react";
import { FormattedMessage } from "react-intl";
import { Card, CardBody, CardFooter, CardHeader, ListGroup, ListGroupItem } from "reactstrap";
import { Link, NavLink as NavLinkRRD } from "react-router-dom";
import { useProduct } from "../../context/ProductContext";

function ProductProgress({ items, onNext, onPrevious }) {
  const { product, isNewProduct } = useProduct();
  const itemKeys = Object.keys(items);
  const isReadOnly = product?.isReadOnly || false;

  return (
    <Card className="product-progress">
      <CardHeader>
        {isReadOnly ? (
          <FormattedMessage id="products.view_product.title" defaultMessage="View a product" />
        ) : isNewProduct ? (
          <FormattedMessage id="products.new_product.title" defaultMessage="Create a new product" />
        ) : (
          <FormattedMessage id="products.edit_product.title" defaultMessage="Edit a product" />
        )}

        {items[itemKeys[0]].to.includes("wizard") && (
          <>
            <hr className="my-2"></hr>
            <Link to={`/products/wizard/create/product-denomination`} className="btn btn-link p-0">
              <i className="fas fa-angle-left"></i> <FormattedMessage id="products.back_to_wizard" defaultMessage="Back to wizard" />
            </Link>
          </>
        )}
      </CardHeader>
      <CardBody className="p-0">
        <ListGroup flush>
          {itemKeys.map((key, i) => (
            <ListGroupItem
              key={i}
              className="d-flex align-items-center"
              to={items[key].to}
              //onClick={(e) => e.preventDefault()}
              disabled={items[key].disabled || false}
              activeClassName="active"
              tag={NavLinkRRD}
              style={{ cursor: "default" }}
            >
              <span className="flex-grow-1">
                {i + 1}. {items[key].description}
              </span>
            </ListGroupItem>
          ))}
        </ListGroup>
      </CardBody>
      <CardFooter>
        {onNext && (
          <button className="btn btn-outline-dark float-right" onClick={onNext}>
            <FormattedMessage id="app.next" defaultMessage="Next" />
          </button>
        )}
        {onPrevious && (
          <button type="button" className="btn btn-outline-danger" onClick={onPrevious}>
            <i className="fas fa-arrow-left"></i> <FormattedMessage id="app.back" defaultMessage="Back" />
          </button>
        )}
      </CardFooter>
    </Card>
  );
}

export default ProductProgress;
