import React from "react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

export function dismissAlert() {
    MySwal.closeModal();
}

export function fireAlert(content, title, icon, params) {
    return MySwal.fire({
        title: title,
        html: content,
        icon: icon,
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn btn-outline-danger',
            cancelButton: 'btn btn-outline-dark',
            denyButton: 'btn btn-outline-dark',
        },
        ...params
      });
}

export function fireConfirm(content, title, icon, confirmText, cancelText, onConfirm, onCancel) {
    MySwal.fire({
        title: title,
        html: content,
        icon: icon,
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn btn-outline-primary',
            cancelButton: 'btn btn-outline-dark'
        },
        showCancelButton: true,
        confirmButtonText: confirmText,
        cancelButtonText: cancelText
      }).then((result) => {
        if (result.isConfirmed) {
            onConfirm();
        } else {
            if (onCancel) onCancel();
        }
      })
}

