import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, ModalBody, ModalHeader, Table } from "reactstrap";

function RelatedErPKitsModal(props) {
    const { isOpen, toggle, onSelect, relatedErPKits } = props;

    return (
        <Modal isOpen={isOpen} toggle={toggle} size={"md"} className={""}>
            <ModalHeader toggle={toggle}><i className="fas fa-link"></i> <FormattedMessage id="curves_generator.related_erp_kits" defaultMessage="Related ErP Kits" /></ModalHeader>
            <ModalBody>
                <Table>
                    <thead className="thead-light">
                        <th><FormattedMessage id="curve_generator.modelo_kit_erp" defaultMessage="Modelo Kit ErP" /></th>
                        <th></th>
                    </thead>
                    {relatedErPKits.map(k => <tr key={k.name}>
                        <td className="align-middle">{k.name}</td>
                        <td>
                            <Button className="btn-outline-primary float-right" onClick={() => onSelect(k)}>
                                <FormattedMessage id="app.open" defaultMessage="Open" /> <i className="fas fa-arrow-right"></i>
                            </Button>
                        </td>
                    </tr>)}
                </Table>
            </ModalBody>
        </Modal>
    );
}

export default RelatedErPKitsModal;