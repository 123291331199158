import React, { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import MultiSearchPanel from "../Search/MultiSearchPanel";
import { useService } from "../../context/ServiceContext";
import { useDropzone } from "react-dropzone";

function CertificateSeriesProductSearch({ useGeneric, saveCertificate }) {
  const { serieComercialLister, serieSAPLister, serieComercialGenericProductLister, serieComercialProductLister } = useService();
  const intl = useIntl();

  let searchFields = [
    {
      id: "series",
      label: intl.formatMessage({
        id: "app.series",
        defaultMessage: "Series",
      }),
      value: "",
    },
    {
      id: "product_model",
      label: intl.formatMessage({
        id: "app.product",
        defaultMessage: "Product",
      }),
      value: "",
    },
  ];

  const searchHandles = {
    series: {
      lister: useGeneric ? serieComercialLister : serieSAPLister,
      step: "series",
      filter: (value) => ({ name: value }),
    },
    series_id: {
      lister: serieSAPLister,
      step: "series",
      filter: (value) => ({ seriesIdCCP: value }),
    },
    series_description: {
      lister: serieSAPLister,
      step: "series",
      filter: (value) => ({ seriesDescriptionCCP: value }),
    },
    product_model: {
      lister: useGeneric ? serieComercialGenericProductLister : serieComercialProductLister,
      step: "product",
      filter: (value) => (useGeneric ? { name: value } : { productoComercial: value }),
    },
    product_code: {
      lister: serieComercialProductLister,
      step: "product",
      filter: (value) => ({ codProductoComercial: value }),
    },
  };

  const [submittedSearch, setSubmittedSearch] = useState(null);

  async function handleSearchSubmit(search) {
    if (search !== null) {
      let handle = searchHandles[search.id];
      setSubmittedSearch({
        step: handle.step,
        filter: handle.filter(search.value),
      });
    } else {
      setSubmittedSearch(null);
    }
  }

  return (
    <>
      <Row>
        <Col>
          <MultiSearchPanel fields={searchFields} onSubmit={handleSearchSubmit} isOpen={true} onToggleOpen={() => {}} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <CertificateSeriesProductSearchResults search={submittedSearch} saveCertificate={saveCertificate}></CertificateSeriesProductSearchResults>
        </Col>
      </Row>
    </>
  );
}

function CertificateSeriesProductSearchResults({ search, saveCertificate }) {
  const { serieComercialLister, serieComercialGenericProductLister } = useService();

  const [error, setError] = useState();

  const [seriesData, setSeriesData] = useState();
  const [productData, setProductData] = useState();

  const [selectedSeries, setSelectedSeries] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [legislations, setLegislations] = useState([
    { id: 0, name: "Legislation 1" },
    { id: 1, name: "Legislation 2" },
    { id: 2, name: "Legislation 3" },
    { id: 3, name: "Legislation 4" },
    { id: 4, name: "Legislation 5" },
  ]);
  const [regulations, setRegulations] = useState();

  const [selectedLegislations, setSelectedLegislations] = useState([]);
  const [selectedRegulations, setSelectedRegulations] = useState([]);

  useEffect(() => {
    if (!search) return;

    setSelectedSeries([]);

    loadData();
  }, [search]);

  useEffect(() => {
    loadSeriesProductsData();
  }, [selectedSeries]);

  useEffect(() => {
    filterRegulations();
  }, [selectedLegislations]);

  async function onSeriesClick(seriesId) {
    let series = [...selectedSeries];
    if (series.includes(seriesId)) series = series.filter((s) => s != seriesId);
    else series = series.concat(seriesId);

    setSelectedSeries(series);
  }

  async function onProductClick(product) {
    let products = [...selectedProducts];
    if (products.some((p) => p.id == product.id)) products = products.filter((p) => p.id != product.id);
    else products = products.concat(product);

    setSelectedProducts(products);
  }

  async function onLegislationClick(id) {
    let legislation = [...selectedLegislations];
    if (legislation.includes(id)) legislation = legislation.filter((s) => s != id);
    else legislation = legislation.concat(id);

    setSelectedLegislations(legislation);
  }

  async function onRegulationClick(id) {
    let regulations = [...selectedRegulations];
    if (regulations.includes(id)) regulations = regulations.filter((s) => s != id);
    else regulations = regulations.concat(id);

    setSelectedRegulations(regulations);
  }

  function filterRegulations() {
    const testRegulations = [
      { id: 0, name: "Regulation 1 of Legislation 1", legislation: 0 },
      { id: 1, name: "Regulation 2 of Legislation 1", legislation: 0 },
      { id: 2, name: "Regulation 3 of Legislation 1", legislation: 0 },
      { id: 3, name: "Regulation 4 of Legislation 1", legislation: 0 },
      { id: 4, name: "Regulation 1 of Legislation 2", legislation: 1 },
      { id: 5, name: "Regulation 2 of Legislation 2", legislation: 1 },
      { id: 6, name: "Regulation 3 of Legislation 2", legislation: 1 },
      { id: 7, name: "Regulation 1 of Legislation 3", legislation: 2 },
      { id: 8, name: "Regulation 2 of Legislation 3", legislation: 2 },
      { id: 9, name: "Regulation 2 of Legislation 5", legislation: 4 },
    ];

    const filteredRegulations = testRegulations.filter((r) => selectedLegislations.includes(r.legislation));
    setRegulations(filteredRegulations);

    if (selectedRegulations?.length > 0) {
      setSelectedRegulations(selectedRegulations.filter((id) => filteredRegulations.some((r) => r.id == id)));
    }
  }

  async function loadData() {
    const filters = [search.filter].filter((f) => f && Object.keys(f).length);
    const lister = search.step == "series" ? serieComercialLister : serieComercialGenericProductLister;

    try {
      let result = await lister.getList(filters, 1, 9999);
      if (search.step == "series") setSeriesData(result.items);
      else setProductData(result.items);
    } catch (e) {
      if (e.localization) {
        setError({ localization: e.localization, traceId: e.traceId });
      }
    }
  }

  async function loadSeriesProductsData() {
    try {
      if (selectedSeries.length == 0) {
        setProductData(null);
        return;
      }

      let products = await serieComercialGenericProductLister.getList([{ seriesIdList: selectedSeries }], 1, 9999);
      setProductData(products.items.filter((p) => p.name));
    } catch (e) {
      if (e.localization) {
        setError({ localization: e.localization, traceId: e.traceId });
      }
    }
  }

  function selectAll() {
    const products = [...selectedProducts];
    for (let i = 0; i < productData.length; i++) {
      const product = productData[i];
      if (products.some((p) => p.id == product.id) == false) {
        products.push(product);
      }
    }
    setSelectedProducts(products);
  }

  function deselectAll() {
    const products = [...selectedProducts];
    for (let i = 0; i < productData.length; i++) {
      const product = productData[i];
      if (products.some((p) => p.id == product.id)) {
        products.push(product);
      }
    }
    const sp = productData.map((p) => p.id);
    setSelectedProducts(products.filter((p) => !sp.includes(p.id)));
  }

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  function save() {
    saveCertificate({
      products: selectedProducts.map((p) => p.id),
      files: acceptedFiles,
      legislations: selectedLegislations,
      regulations: selectedRegulations,
    });
  }

  return (
    <>
      {(seriesData || productData) && (
        <Card>
          <CardHeader>Product selection</CardHeader>
          <CardBody>
            <Row>
              <Col sm={6}>
                <h3>Series</h3>
                <div style={{ maxHeight: 300, overflowY: "scroll" }}>
                  {seriesData
                    ?.filter((s) => s.name)
                    .map((s) => (
                      <div key={s.id} className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={selectedSeries.includes(s.id)}
                          id={`check-series-${s.id}`}
                          onClick={() => onSeriesClick(s.id)}
                        />
                        <label className="form-check-label" for={`check-series-${s.id}`}>
                          {s.name}
                        </label>
                      </div>
                    ))}
                </div>
              </Col>
              <Col sm={6}>
                {productData && (
                  <>
                    <h3 style={{ display: "inline-block" }}>Products</h3>
                    <button className="btn btn-link btn-sm" onClick={selectAll}>
                      Select all
                    </button>
                    <button className="btn btn-link btn-sm" onClick={deselectAll}>
                      Deselect all
                    </button>
                    <div style={{ maxHeight: 300, overflowY: "scroll" }}>
                      {productData
                        ?.filter((p) => p.name)
                        .map((p) => (
                          <div key={p.id} className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={selectedProducts.some((p1) => p1.id == p.id)}
                              id={`check-product-${p.id}`}
                              onClick={() => onProductClick(p)}
                            />
                            <label className="form-check-label" for={`check-product-${p.id}`}>
                              {p.name}
                            </label>
                          </div>
                        ))}
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
      {selectedProducts?.length > 0 && (
        <>
          <Row>
            <Col sm="6">
              <Card className="mt-3">
                <CardHeader>Upload Certificates</CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <button type="button" onClick={open} className="btn btn-outline-dark">
                              Add
                            </button>
                          </div>
                          {files.length > 0 && (
                            <>
                              <h3 className="card-title">Files to uploaded</h3>
                              <ul>{files}</ul>
                            </>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6">
              <Card className="mt-3">
                <CardHeader className="d-flex align-items-center">
                  <div className="flex-grow-1">Selected Products</div>
                  <button type="button" className="btn btn-link btn-sm" onClick={() => setSelectedProducts([])}>
                    Remove all
                  </button>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      {selectedProducts.map((p) => (
                        <Fragment key={p.id}>
                          <span class="badge badge-pill badge-primary">
                            {p.name} <i className="fas fa-times" style={{ cursor: "pointer" }} onClick={() => onProductClick(p)}></i>
                          </span>{" "}
                        </Fragment>
                      ))}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="mt-3">
                <CardHeader>Define Regulations</CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="6">
                      <h3>Legislations</h3>
                      <div style={{ maxHeight: 300, overflowY: "scroll" }}>
                        {legislations.map((s) => (
                          <div key={s.id} className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={selectedLegislations.includes(s.id)}
                              id={`check-legislation-${s.id}`}
                              onClick={() => onLegislationClick(s.id)}
                            />
                            <label className="form-check-label" for={`check-legislation-${s.id}`}>
                              {s.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </Col>
                    <Col sm="6">
                      {regulations?.length > 0 && (
                        <>
                          <h3>Regulations</h3>
                          {regulations?.map((s) => (
                            <div key={s.id} className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={selectedRegulations.includes(s.id)}
                                id={`check-regulation-${s.id}`}
                                onClick={() => onRegulationClick(s.id)}
                              />
                              <label className="form-check-label" for={`check-regulation-${s.id}`}>
                                {s.name}
                              </label>
                            </div>
                          ))}
                        </>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <button type="button" className="btn btn-outline-danger" onClick={save}>
                Save
              </button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default CertificateSeriesProductSearch;
