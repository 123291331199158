import React, { useState } from "react";
import "chartjs-plugin-dragdata";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  CustomInput,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Row,
  UncontrolledButtonDropdown,
} from "reactstrap";
import Input from "../Input";
import CollapsibleCard from "../CollapsibleCard";
import { useConfig } from "../../context/ConfigContext";
import CurveLimitsSelector from "./CurveLimitsSelector";
import CurvesTable from "./CurvesTable";
import CurvesChart from "./CurvesChart";
import { fireConfirm } from "../../utils/alerts";
import ErrorAlertContent from "../ErrorAlertContent";
import AlertWithIcon from "../AlertWithIcon";

function Curves(props) {
  const {
    openedCurveTab,
    onOpenCurveTab,
    id,
    interpolationCoefficient,
    onChangeInterpolationCoefficient,
    autoInterpolation,
    onChangeAutoInterpolation,
    isSelectionInterpolationDisabled,
    onChangeIsSelectionInterpolationDisabled,
    isSaved,
    onRecalculateInterpolatedCurve,
    laboratoryTestData,
    interpolatedData,
    isInterpolatedDataModified,
    selectionData,
    readOnly,
    onChangeInterpolatedDataValue,
    onInsertInterpolatedDataRow,
    onRemoveInterpolatedDataRow,
    onSave,
  } = props;
  const { precision, columns, report } = useConfig("genesis.gdec");
  const [testDisplayTypeSelected, setTestDisplayTypeSelected] = useState(1);
  const reportLocation = id
    ? `${report.root}/ReportServer/Pages/ReportViewer.aspx?%2fEasyVent%2f533+Puntos+Curvas&rs:Command=Render&rs:Format=EXCEL&idEnsayo=%20${id.value}`
    : null;
  const intl = useIntl();

  function handleCalculateSplineClicked(e) {
    e.preventDefault();
    onRecalculateInterpolatedCurve && onRecalculateInterpolatedCurve(e);
  }

  function getActiveData() {
    if (openedCurveTab === "test") {
      return laboratoryTestData;
    } else if (openedCurveTab === "interpolated") {
      return interpolatedData;
    } else if (openedCurveTab === "selection") {
      return selectionData;
    }
  }

  function handleRowUpdate(index, key, floatValue) {
    onChangeInterpolatedDataValue && onChangeInterpolatedDataValue(index, key, floatValue);
  }

  function handleRowInsertion(index) {
    onInsertInterpolatedDataRow && onInsertInterpolatedDataRow(index);
  }

  function handleRowDeletion(index) {
    onRemoveInterpolatedDataRow && onRemoveInterpolatedDataRow(index);
  }

  function handleReportClick() {
    if (!reportLocation) {
      return;
    }

    window.open(reportLocation);
  }

  function handleUnsavedReportClick(e) {
    e.preventDefault();

    if (!reportLocation) {
      return;
    }

    const handleSaveAndContinueClick = () => {
      onSave().then(handleReportClick);
    };

    fireConfirm(
      <ErrorAlertContent
        message={intl.formatMessage({
          id: "curves_generator.save_curve_test_for_report",
          defaultMessage: "You must save the idEnsayo to see an up-to-date report.",
        })}
        traceId={e.traceId}
      />,
      intl.formatMessage({ id: "app.warning", defaultMessage: "Warning" }),
      "warning",
      intl.formatMessage({ id: "app.save_and_continue", defaultMessage: "Save and Continue" }),
      intl.formatMessage({ id: "app.cancel", defaultMessage: "Cancel" }),
      handleSaveAndContinueClick
    );
  }

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col xl={9}>
              <ButtonGroup>
                <Button className="btn-outline-primary" onClick={() => onOpenCurveTab("test")} active={openedCurveTab === "test"}>
                  <FormattedMessage id="curve_viewer.test_points" defaultMessage="Test Points" />
                </Button>
                <Button className="btn-outline-primary" onClick={() => onOpenCurveTab("interpolated")} active={openedCurveTab === "interpolated"}>
                  <FormattedMessage id="curve_viewer.interpolated_points" defaultMessage="Interpolated Points" />
                </Button>
                <Button className="btn-outline-primary" onClick={() => onOpenCurveTab("selection")} active={openedCurveTab === "selection"}>
                  <FormattedMessage id="curve_viewer.selection_curve" defaultMessage="Selection curve" />
                </Button>
              </ButtonGroup>
            </Col>

            <Col xl={3} className="mt-3 mt-xl-0">
              <div className="d-flex justify-content-end">
                <ButtonGroup className="mr-3">
                  <Button color="success" onClick={() => setTestDisplayTypeSelected(1)} active={testDisplayTypeSelected === 1}>
                    <i className="fas fa-table"></i>
                  </Button>
                  <Button color="success" onClick={() => setTestDisplayTypeSelected(2)} active={testDisplayTypeSelected === 2}>
                    <i className="fas fa-chart-area"></i>
                  </Button>
                </ButtonGroup>

                <UncontrolledButtonDropdown>
                  <DropdownToggle caret>
                    <i className="fas fa-cog"></i>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={isSaved || readOnly ? handleReportClick : handleUnsavedReportClick}>
                      <a target="_blank" href="#" className="text-decoration-none text-reset">
                        <i class="fas fa-file-excel"></i> <FormattedMessage id="curves_generator.export_to_excel" defaultMessage="Export to Excel" disabled={!reportLocation} />
                      </a>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              {openedCurveTab === "interpolated" && (
                <>
                  {isInterpolatedDataModified && (
                    <Row>
                      <Col>
                        <AlertWithIcon className="alert-warning" icon={<i className="fas fa-exclamation-triangle"></i>}>
                          <FormattedMessage
                            id="curves_generator.interpolated_points_modified"
                            defaultMessage="The interpoldated points are modified due to duplicate airflow in test points."
                          />
                        </AlertWithIcon>
                      </Col>
                    </Row>
                  )}
                  <CollapsibleCard className="mb-3" title={intl.formatMessage({ id: "curve_viewer.interpolation_settings", defaultMessage: "Interpolation settings" })}>
                    <CardBody>
                      <Row>
                        <Col md={6}>
                          <Label>
                            <FormattedMessage id="curve_viewer.interpolation_coefficient" defaultMessage="Interpolation coefficient" />
                          </Label>
                          <Input type="select" value={interpolationCoefficient} onChange={(e) => onChangeInterpolationCoefficient(e.target.value)} disabled={autoInterpolation}>
                            <option value="0.85">85</option>
                            <option value="0.90">90</option>
                            <option value="0.95">95</option>
                            <option value="1">100</option>
                            <option value="1.01">101</option>
                            <option value="1.02">102</option>
                            <option value="1.03">103</option>
                            <option value="1.04">104</option>
                            <option value="1.05">105</option>
                            {interpolationCoefficient == 1.06 && <option disabled value="1.06">106</option>}
                            {interpolationCoefficient == 1.07 &&  <option disabled value="1.07">107</option>}
                            {interpolationCoefficient == 1.08 && <option disabled value="1.08">108</option>}
                            {interpolationCoefficient == 1.09 && <option disabled value="1.09">109</option>}
                            {interpolationCoefficient == 1.1 && <option disabled value="1.1">110</option>}
                          </Input>
                        </Col>
                        <Col md={3}>
                          <CustomInput
                            type="switch"
                            className="my-4"
                            label={intl.formatMessage({ id: "app.auto", defaultMessage: "Auto" })}
                            id="auto-coef"
                            checked={autoInterpolation}
                            onChange={(e) => onChangeAutoInterpolation(e.target.checked)}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <div className="d-flex justify-content-end">
                        <Button type="button" color="primary" onClick={handleCalculateSplineClicked}>
                          <FormattedMessage id="curves_generator.calculate_spline" defaultMessage="Calculate spline" />
                        </Button>
                      </div>
                    </CardFooter>
                  </CollapsibleCard>
                </>
              )}

              {openedCurveTab === "selection" && (
                <CollapsibleCard className="mb-3" title={intl.formatMessage({ id: "curve_viewer.selection_settings", defaultMessage: "Selection settings" })}>
                  <CardBody>
                    {interpolatedData && (
                      <Row>
                        <Col>
                          <CurveLimitsSelector columns={columns.displayed} decimalScale={precision} {...props} />
                        </Col>
                      </Row>
                    )}

                    <Row>
                      <Col xl="3" className="ml-auto">
                        <CustomInput
                          type="switch"
                          className="float-right"
                          label={intl.formatMessage({ id: "curves_generator.no_spline", defaultMessage: "No spline" })}
                          id="no-spline"
                          checked={isSelectionInterpolationDisabled}
                          onChange={(e) => onChangeIsSelectionInterpolationDisabled(e.target.checked)}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </CollapsibleCard>
              )}
            </Col>
          </Row>

          <Row>
            <Col>
              <CurveTableChart
                dataSet={getActiveData()}
                columns={columns.displayed}
                onUpdateRow={handleRowUpdate}
                onInsertRow={handleRowInsertion}
                onDeleteRow={handleRowDeletion}
                viewMode={testDisplayTypeSelected}
                enableEditing={openedCurveTab === "interpolated"}
                precision={precision}
              ></CurveTableChart>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}

function CurveTableChart(props) {
  function handleDragPoints(index, variable, value) {
    props.onUpdateRow && props.onUpdateRow(index, variable, value.y);
  }

  return props.viewMode === 1 ? <CurvesTable {...props} /> : <CurvesChart {...props} onDragUpdate={handleDragPoints} />;
}

export default Curves;
