import React, { useEffect, useState, Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Button,
  Col,
  CustomInput,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Popover,
  PopoverHeader,
  PopoverBody,
  UncontrolledPopover,
} from "reactstrap";
import { useService } from "../../context/ServiceContext";
import { datosPlacaModes, parseStructureValues } from "../../plates/PlateStructures";
import LoadedContent from "../LoadedContent";
import PlaqueIcons from "./PlaqueIcons";
import TemplateAttributes from "./TemplateAttributes";
import TemplateBaseProductSearch from "./TemplateBaseProductSearch";
import ProductInfo from "../ProductCreation/ProductInfo";
import { intersectionWith } from "lodash";

function TemplateSearch({
  availableTemplates,
  companies,
  templateProductData,
  updateTemplate,
  templates,
  template,
  duplicateTemplate,
  removeTemplate,
  mode,
  updateProductTests,
  editMode,
  updateMode,
  updateTemplatesWithExistingData,
  codProductoComercial,
  toggleCloneTemplateModal,
  keepLinkedDescriptionAttributeSwitch,
  setKeepLinkedDescriptionAttributeSwitch,
}) {
  const { plateTemplateSectionsProvider, plateProductAndTestAttributesProvider } = useService();
  const [reportTemplate, setReportTemplate] = useState();
  const [company, setCompany] = useState(template?.keyCompany?.toString());
  const [availableTemplatesCodes, setAvailableTemplatesCodes] = useState([]);
  const [availableTemplatesDescriptions, setAvailableTemplatesDescriptions] = useState([]);
  const [availableTemplatesTypes, setAvailableTemplatesTypes] = useState([]);
  const [availableTemplatesSizes, setAvailableTemplatesSizes] = useState([]);
  const [isAttributesModalOpen, setIsAttributesModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const intl = useIntl();

  async function toggleAttributesModal() {
    setIsAttributesModalOpen(!isAttributesModalOpen);
  }

  function filterTemplates({ keyCompany, templateDescription, templateCode, templateNumber, templateSize }) {
    var _template = { ...template };

    if (keyCompany == undefined) keyCompany = company;
    if (templateDescription == undefined) templateDescription = _template.templateDescription;
    if (templateCode == undefined) templateCode = _template.templateCode;
    if (templateNumber == undefined) templateNumber = _template.templateNumber;
    if (templateSize == undefined) templateSize = _template.templateSize;

    const filteredTemplates = availableTemplates.filter(
      (t) =>
        t.keyCompany === keyCompany &&
        (t.id === templateDescription || isNaN(templateDescription) || templateDescription == "") &&
        (t.codeTemplate === templateCode || isNaN(templateCode) || templateCode == "") &&
        (t.type.id === templateNumber || isNaN(templateNumber) || templateNumber == "") &&
        (t.size.id === templateSize || isNaN(templateSize) || templateSize == "")
    );

    // Template description

    var _availableTemplatesDescriptions = [...new Set(filteredTemplates.map((t) => t.id))].map((t) => {
      return { id: t, name: availableTemplates.find((at) => at.id == t).name };
    });
    setAvailableTemplatesDescriptions(_availableTemplatesDescriptions.sort((a, b) => (a.name < b.name ? -1 : 1)));
    if (_availableTemplatesDescriptions.length == 1) templateDescription = _availableTemplatesDescriptions[0].id;
    _template.templateDescription = templateDescription;

    // Template code

    var _availableTemplatesCodes = [
      ...new Set(
        filteredTemplates.map((t) => {
          return { codeTemplate: t.codeTemplate, image: t.image };
        })
      ),
    ].map((t) => {
      return { id: t.codeTemplate, name: t.codeTemplate, image: t.image };
    });
    setAvailableTemplatesCodes(_availableTemplatesCodes.sort((a, b) => (a.name < b.name ? -1 : 1)));
    if (_availableTemplatesCodes.length == 1) templateCode = _availableTemplatesCodes[0].id;
    _template.templateCode = templateCode;

    // Template types

    var _availableTemplatesTypes = [...new Set(filteredTemplates.map((t) => t.type.id))].map((t) => {
      return { id: t, name: availableTemplates.find((at) => at.type.id == t).type.name };
    });
    setAvailableTemplatesTypes(_availableTemplatesTypes.sort((a, b) => (a.name < b.name ? -1 : 1)));
    if (_availableTemplatesTypes.length == 1) templateNumber = _availableTemplatesTypes[0].id;
    _template.templateNumber = templateNumber;

    // Template sizes

    var _availableTemplatesSizes = [...new Set(filteredTemplates.map((t) => t.size.id))].map((t) => {
      const size = availableTemplates.find((at) => at.size.id == t).size;
      return {
        id: t,
        name: `${size.width}x${size.height}`,
      };
    });
    setAvailableTemplatesSizes(_availableTemplatesSizes.sort((a, b) => (a.name < b.name ? -1 : 1)));
    if (_availableTemplatesSizes.length == 1) templateSize = _availableTemplatesSizes[0].id;
    _template.templateSize = templateSize;

    updateTemplate(_template);
  }

  function updateByCompany(keyCompany) {
    setCompany(keyCompany);
    filterTemplates({ keyCompany: keyCompany });
  }

  function updateByTemplateDescription(id) {
    if (id == undefined || isNaN(id) || id == "") {
      filterTemplates({ templateDescription: "", templateCode: "", templateSize: "" });
    } else {
      filterTemplates({ templateDescription: id });
    }
  }

  function updateByTemplateCode(id) {
    if (id == undefined || isNaN(id) || id == "") {
      filterTemplates({ templateCode: "", templateSize: "", templateDescription: "" });
    } else {
      filterTemplates({ templateCode: id });
    }
  }

  function updateByTemplateType(id) {
    if (id == undefined || isNaN(id) || id == "") {
      filterTemplates({ templateNumber: "", templateSize: "", templateCode: "", templateDescription: "" });
    } else {
      filterTemplates({ templateNumber: id });
    }
  }

  function updateByTemplateSize(id) {
    if (id == undefined || isNaN(id) || id == "") {
      filterTemplates({ templateSize: "", templateCode: "", templateDescription: "" });
    } else {
      filterTemplates({ templateSize: id });
    }
  }

  function clearFilters() {
    setCompany("");
    updateTemplate({ ...template, keyCompany: "", templateDescription: "", templateCode: "", templateNumber: "", templateSize: "", icons: [] });
  }

  function updateFromAttributes(templateOptions) {
    filterTemplates({
      keyCompany: templateOptions.keyCompany,
      templateDescription: templateOptions.id,
      templateCode: templateOptions.codeTemplate,
      templateNumber: templateOptions.type.id,
      templateSize: templateOptions.size.id,
    });
  }

  function changeCodeHandler(value) {
    template.changeCode = value;
    updateTemplate(template);
  }

  async function getProductAndTestAttibutes(templateDescription, selectedTests, clonedProductCode) {
    return await plateProductAndTestAttributesProvider.get({
      productCode: codProductoComercial,
      clonedProductCode: clonedProductCode,
      idTemplate: templateDescription,
      idEnsayo1: selectedTests[0]?.id || 0,
      idEnsayo2: selectedTests[1]?.id || 0,
      idEnsayo3: selectedTests[2]?.id || 0,
      idEnsayo4: selectedTests[3]?.id || 0,
      idEnsayo5: selectedTests[4]?.id || 0,
      idEnsayo6: selectedTests[5]?.id || 0,
    });
  }

  async function updateTestAttributes(productTestAttributes, tests, clonedWithValues, resetVisibility) {
    if (!tests) tests = template.selectedTests;
    if (tests?.length > 0) {
      if (!productTestAttributes) productTestAttributes = await getProductAndTestAttibutes(template.templateDescription, tests);
      const testAttributes = productTestAttributes.tests;
      return tests.map((t, i) => {
        return {
          ...t,
          attributes: testAttributes[i].attributes.map((a) => {
            let oldTestAttribute = !clonedWithValues && t.attributes ? t.attributes.find((ta) => ta.name === a.name) : null;
            let hasModified = oldTestAttribute && oldTestAttribute.value != null && oldTestAttribute.value != oldTestAttribute.originalValue;

            return {
              name: a.name,
              unit: a.unit,
              inputType: a.inputType,
              value: hasModified ? oldTestAttribute.value : a.value || a.originalValue,
              originalValue: a.originalValue,
              modified: a.modified,
              visible: resetVisibility || undefined,
            };
          }),
        };
      });
    }
    //   return tests.map((t, i) => {
    //     let testAttribute = testAttributes.find((ta) => ta.id == t.id);
    //     if(testAttribute){
    //       return {
    //         ...t,
    //         attributes: testAttribute.attributes.map((a) => {
    //           let oldTestAttribute = !clonedWithValues && t.attributes ? t.attributes.find((ta) => ta.name === a.name) : null;
    //           let hasModified = oldTestAttribute && oldTestAttribute.value != null && oldTestAttribute.value != oldTestAttribute.originalValue;
  
    //           return {
    //             name: a.name,
    //             unit: a.unit,
    //             inputType: a.inputType,
    //             value: hasModified ? oldTestAttribute.value : a.value || a.originalValue,
    //             originalValue: a.originalValue,
    //             modified: a.modified,
    //             visible: resetVisibility || undefined,
    //           };
    //         }),
    //       };
    //     }

    //   });
    // }
    
     return [];
  }

  async function updateTestAttributeBySelectedIdEnsayo(productTestAttributes, resetVisibility) {
    let tests = [];
    if (productTestAttributes?.tests.length > 0) {
      for (let i = 0; i < productTestAttributes?.tests.length; i++) {
        const t = productTestAttributes?.tests[i];
        const addTest = t.attributes.findIndex((a) => a.name.toUpperCase() == "IDENSAYO") > -1;
        if (addTest) {
          tests.push({
            ...t,
            attributes: t.attributes.map((a) => {
              return {
                name: a.name,
                unit: a.unit,
                inputType: a.inputType,
                value: a.modified && a.value ? a.value : a.originalValue,
                originalValue: a.originalValue,
                modified: a.modified,
                visible: resetVisibility || undefined,
              };
            }),
          });
        }
      }
    }
    return tests ?? [];
  }

  async function getTemplateAttributes() {
    let productTestAttributes = await getProductAndTestAttibutes(template.templateDescription, template.selectedTests, template.clonedFrom);
    changeCodeHandler(productTestAttributes.changeCode);

    // Get template report data
    let templateValues = await plateTemplateSectionsProvider.get({
      codModeloProducto: templateProductData.codModeloProducto,
      codModeloProductoVersion: templateProductData.codModeloProductoVersion,
      codProductoComercial: templateProductData.code,
      template: template.templateDescription,
    });

    const productAttributes = productTestAttributes.productAttributes;

    const tests =
      template.selectedTests?.length > 0
        ? await updateTestAttributes(productTestAttributes, template.selectedTests, template.clonedFrom, true)
        : await updateTestAttributeBySelectedIdEnsayo(productTestAttributes);

    let t = {
      ...template,
      clonedFrom: undefined,
      attributes: productAttributes.map((a) => {
        let oldAttribute = !template.clonedFrom && template.attributes ? template.attributes.find((ta) => ta.name === a.name) : null;
        let hasModified = oldAttribute && oldAttribute.value != null && oldAttribute.value != oldAttribute.originalValue;
        return {
          name: a.name,
          unit: a.unit,
          inputType: a.inputType,
          value: hasModified ? oldAttribute.value : a.value || a.originalValue,
          originalValue: a.originalValue,
          sourceType: a.sourceType,
          modified: a.modified,
          visible: true,
        };
      }),
      selectedTests: tests,
      keyCompany: availableTemplates.find((t) => t.id === template.templateDescription).keyCompany,
    };
    templateValues.forEach((structure) => parseStructureValues(structure, t));
    updateTemplate({ ...t });
    setReportTemplate(templateValues);
    setLoading(false);
  }

  useEffect(() => {
    if (template.templateDescription) {
      setLoading(true);
      getTemplateAttributes();
    }

    var keyCompany = "";
    if (template.templateDescription) {
      keyCompany = availableTemplates.filter((t) => t.id === template.templateDescription || !template.templateDescription)[0]?.keyCompany;
      setCompany(keyCompany);
      filterTemplates({ keyCompany: keyCompany, templateDescription: template.templateDescription });
    }
  }, [template?.templateDescription]);

  useEffect(() => {
    if (reportTemplate) {
      updateTemplatesWithExistingData();
    }
  }, [reportTemplate]);

  const maxTests = availableTemplates.find((at) => at.id == template?.templateDescription)?.maxTests;

  return (
    <LoadedContent loading={loading} overlay={true}>
      <div className="d-flex align-items-center bg-secondary">
        <div className="flex-grow-1">
          <div>
            <Row>
              <Col>
                <div className="d-flex align-items-center">
                  <span className="mx-3">
                    <FormattedMessage id="datos_placa.active" defaultMessage="Active" />
                  </span>{" "}
                  <CustomInput
                    type="switch"
                    id={`custominput-test`}
                    checked={template.active === true || template.active == undefined}
                    onChange={(e) => updateTemplate({ ...template, active: e.target.checked })}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div>
          <Button color="link" type="button" onClick={toggleCloneTemplateModal}>
            <i className="fas fa-clone"></i> <FormattedMessage id="datos_placa.clone_from_another_product_template" defaultMessage="Clone from another product template" />
          </Button>
          <Button
            color="link"
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              duplicateTemplate(template);
            }}
          >
            <i className="fas fa-clone"></i> <FormattedMessage id="datos_placa.duplicate_template" defaultMessage="Duplicate this template" />
          </Button>
        </div>
        <div>
          <Button
            color="link"
            type="button"
            disabled={templates.length === 1}
            onClick={(e) => {
              e.stopPropagation();
              removeTemplate(template);
            }}
          >
            <i className="fas fa-trash"></i> <FormattedMessage id="app.remove" defaultMessage="Remove" />
          </Button>
        </div>
      </div>
      <hr className="mt-0 mb-2"></hr>
      <div className="px-3">
        <Row>
          <Col xl="6">
            <h3>
              <FormattedMessage id="datos_placa.select_templates" defaultMessage="Select templates" />
            </h3>
            <Row className="mb-1">
              <Col
                sm={{
                  offset: 6,
                  size: 6,
                }}
              >
                <button className="btn btn-outline-danger btn-block" type="button" onClick={toggleAttributesModal}>
                  <FormattedMessage id="datos_placa.select_using_attributes" defaultMessage="Select using attributes" />
                </button>
              </Col>
            </Row>
            <FormGroup row className="my-0">
              <Label sm={6}>
                <FormattedMessage id="datos_placa.company" defaultMessage="Company" />{" "}
                <ProductInfo formattedMessage={{ id: "datos_placa.company_help", defaultMessage: "company_help" }}></ProductInfo>
              </Label>
              <Col sm={6}>
                <Input type="select" value={company} onChange={(e) => updateByCompany(parseInt(e.target.value))}>
                  <option value="">{intl.formatMessage({ id: "product_information.select_an_option", defaultMessage: "Please select an option" })}</option>
                  {availableTemplates?.length > 0
                    ? companies
                        .filter((c) => availableTemplates.some((at) => at.keyCompany == c.id))
                        .map((a) => (
                          <option key={a.id} value={a.id}>
                            {a.value}
                          </option>
                        ))
                    : null}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row className="my-0">
              <Label sm={6}>
                <FormattedMessage id="datos_placa.template_number" defaultMessage="Template type" />{" "}
                <ProductInfo formattedMessage={{ id: "datos_placa.template_type_help", defaultMessage: "template_type_help" }}></ProductInfo>
              </Label>
              <Col sm={6}>
                <TemplatePropertySelect
                  options={availableTemplatesTypes}
                  value={template.templateNumber}
                  onChange={(e) => updateByTemplateType(parseInt(e.target.value))}
                  disabled={company == null || company == "" || isNaN(company)}
                ></TemplatePropertySelect>
              </Col>
            </FormGroup>
            <FormGroup row className="my-0">
              <Label sm="6">
                <FormattedMessage id="datos_placa.template_codes" defaultMessage="Template code" />{" "}
                <ProductInfo formattedMessage={{ id: "datos_placa.template_code_help", defaultMessage: "template_code_help" }}></ProductInfo>
              </Label>
              <Col sm="6">
                {/* <TemplatePropertySelect
                  options={availableTemplatesCodes}
                  value={template.templateCode}
                  onChange={(e) => updateByTemplateCode(e.target.value)}
                  disabled={company == null || company == "" || isNaN(company)}
                ></TemplatePropertySelect> */}

                <TemplatePropertyDropdownWithImage
                  options={availableTemplatesCodes}
                  value={template.templateCode}
                  onChange={(e) => updateByTemplateCode(e.target.value)}
                  disabled={company == null || company == "" || isNaN(company)}
                ></TemplatePropertyDropdownWithImage>
              </Col>
            </FormGroup>
            <FormGroup row className="my-0">
              <Label sm="6">
                <FormattedMessage id="datos_placa.template_description" defaultMessage="Template description" />{" "}
                <ProductInfo formattedMessage={{ id: "datos_placa.template_description_help", defaultMessage: "template_description_help" }}></ProductInfo>
              </Label>
              <Col sm="6">
                <TemplatePropertySelect
                  options={availableTemplatesDescriptions}
                  value={template.templateDescription}
                  onChange={(e) => updateByTemplateDescription(parseInt(e.target.value))}
                  disabled={company == null || company == "" || isNaN(company)}
                ></TemplatePropertySelect>
              </Col>
            </FormGroup>
            <FormGroup row className="my-0">
              <Label sm={6}>
                <FormattedMessage id="datos_placa.template_size" defaultMessage="Template size" />{" "}
                <ProductInfo formattedMessage={{ id: "datos_placa.template_size_help", defaultMessage: "template_size_help" }}></ProductInfo>
              </Label>
              <Col sm={6}>
                <TemplatePropertySelect
                  options={availableTemplatesSizes}
                  value={template.templateSize}
                  onChange={(e) => updateByTemplateSize(parseInt(e.target.value))}
                  disabled={company == null || company == "" || isNaN(company)}
                ></TemplatePropertySelect>
              </Col>
            </FormGroup>
            <button className="btn btn-outline-danger" type="button" onClick={clearFilters}>
              <FormattedMessage id="datos_placa.clear_filters" defaultMessage="Clear filters" />
            </button>
          </Col>
          {/* <Col xl="6">
            {plaqueSize && templateProductData && reportTemplate && (
              <Plaque
                size={plaqueSize}
                image={plaqueImage}
                productData={templateProductData}
                template={template}
                reportTemplate={reportTemplate}
                updateTemplate={updateTemplate}
              ></Plaque>
            )}
          </Col> */}
        </Row>
        {/* {templateProductData && reportTemplate && template.templateDescription && (mode === datosPlacaModes.DEPENDENT || mode === datosPlacaModes.IDENSAYO) && (
          <div>
            <hr></hr>
            <div style={{ maxWidth: 800 }}>
              <TemplateBaseProductSearch
                mode={mode}
                template={template}
                updateTemplate={updateTemplate}
                updateProductTests={updateProductTests}
                editMode={editMode}
                productData={templateProductData}
                maxTests={maxTests}
                getProductAndTestAttibutes={getProductAndTestAttibutes}
              ></TemplateBaseProductSearch>
            </div>
          </div>
        )} */}
        {loading == false && templateProductData && reportTemplate && template.templateDescription && (
          <>
            <Row>
              <Col>
                <div>
                  <TemplateAttributes
                    productData={templateProductData}
                    template={template}
                    updateTemplate={updateTemplate}
                    updateProductTests={updateProductTests}
                    maxTests={maxTests}
                    mode={mode}
                    updateMode={updateMode}
                    updateTestAttributes={updateTestAttributes}
                    keepLinkedDescriptionAttributeSwitch={keepLinkedDescriptionAttributeSwitch}
                    setKeepLinkedDescriptionAttributeSwitch={setKeepLinkedDescriptionAttributeSwitch}
                  ></TemplateAttributes>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {templateProductData && reportTemplate && (
                  <PlaqueIcons productData={templateProductData} template={template} reportTemplate={reportTemplate} updateTemplate={updateTemplate}></PlaqueIcons>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup className="mb-0">
                  <h4 sm="3">{intl.formatMessage({ id: "product_information.change_code", defaultMessage: "Change code" })}</h4>
                  <Input
                    className="col-sm-3"
                    type="number"
                    value={template.changeCode}
                    onChange={(e) => changeCodeHandler(e.target.value)}
                    // style={a.sourceType == 5 ? { backgroundColor: "#e9ecef" } : {}}
                    // onChange={(e) => updateAttribute(a.name, e.target.value)}
                  />{" "}
                </FormGroup>
              </Col>
            </Row>
          </>
        )}
      </div>
      <Modal isOpen={isAttributesModalOpen} toggle={toggleAttributesModal} size={"lg"} className={""}>
        <ModalBody className="p-0">
          <TemplateUniqueAttributes companies={companies} availableTemplates={availableTemplates} update={updateFromAttributes} />
        </ModalBody>
      </Modal>
    </LoadedContent>
  );
}

function TemplatePropertySelect({ value, onChange, options, disabled }) {
  const intl = useIntl();
  return (
    <Input type="select" value={value} onChange={onChange} disabled={disabled}>
      <option value="">{intl.formatMessage({ id: "product_information.select_an_option", defaultMessage: "Please select an option" })}</option>
      {options.map((a) => (
        <option key={a.id} value={a.id}>
          {a.name}
        </option>
      ))}
    </Input>
  );
}

function TemplatePropertyDropdownWithImage({ value, onChange, options, disabled }) {
  const intl = useIntl();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [templateImage, setTemplateImage] = useState();

  function onMouseEnter(image) {
    setPopoverOpen(true);
    setTemplateImage(`https://easyventtest.solerpalau.com/images/products/${image}`);
    //setTemplateImage("https://easyventtest.solerpalau.com/images/products/15-PitchAngle-TipoB-Trifasico.png");
  }
  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} id="popover" className="w-100">
        <DropdownToggle caret className="w-100">
          {value != undefined && value != "" ? value : intl.formatMessage({ id: "product_information.select_an_option", defaultMessage: "Please select an option" })}
        </DropdownToggle>
        <DropdownMenu>
          {options.map((a) => (
            <DropdownItem
              className="form-control"
              key={a.id}
              value={a.id}
              onMouseEnter={() => onMouseEnter(a.image)}
              onMouseLeave={() => setPopoverOpen(false)}
              onMouseUp={() => setPopoverOpen(false)}
              onClick={onChange}
            >
              {a.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
      <Popover target="popover" toggle={popoverOpen} isOpen={popoverOpen}>
        <PopoverBody>
          <img src={templateImage} />
        </PopoverBody>
      </Popover>
    </>
  );
}

function TemplateUniqueAttributes({ companies, availableTemplates, update }) {
  const { plateUniqueAttributesProvider } = useService();
  const [attributes, setAttributes] = useState();
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [templates, setTemplates] = useState([]);

  var _availableTemplatesTypes = Object.fromEntries(
    [...new Set(availableTemplates.map((t) => t.type.id))].map((t) => {
      return [t, { id: t, name: availableTemplates.find((at) => at.type.id == t).type.name }];
    })
  );

  // Template sizes

  var _availableTemplatesSizes = Object.fromEntries(
    [...new Set(availableTemplates.map((t) => t.size.id))].map((t) => {
      const size = availableTemplates.find((at) => at.size.id == t).size;
      return [
        t,
        {
          id: t,
          name: `${size.width}x${size.height}`,
        },
      ];
    })
  );

  var _availableCompanies = Object.fromEntries(companies.map((x) => [x.id, x]));

  useEffect(() => {
    getAttributes();
  }, []);

  useEffect(() => {
    if (!attributes) return;
    const arrays = selectedAttributes.map((sa) => attributes[sa]);
    setTemplates(intersectionWith(...arrays, (a, b) => a.id == b.id));
  }, [selectedAttributes]);

  async function getAttributes() {
    let uniqueAttributes = await plateUniqueAttributesProvider.get();
    setAttributes(uniqueAttributes);
  }

  function handleSelected(attributeKey, checked) {
    if (checked) {
      setSelectedAttributes(selectedAttributes.concat(attributeKey));
    } else {
      setSelectedAttributes(selectedAttributes.filter((a) => a != attributeKey));
    }
  }

  return (
    <Row className="mx-0">
      <Col className="py-3">
        <h2>
          <FormattedMessage id="datos_placa.available_fields" defaultMessage="Available fields" />
        </h2>
        <div style={{ maxHeight: 300, overflowY: "auto" }}>
          {attributes &&
            Object.keys(attributes).map((a, i) => (
              <FormGroup check key={a}>
                <Input type="checkbox" id={`attribute-name-${i}`} checked={selectedAttributes.includes(a)} onChange={(e) => handleSelected(a, e.target.checked)} />{" "}
                <Label for={`attribute-name-${i}`} check>
                  {a}
                </Label>
              </FormGroup>
            ))}
        </div>
        <div>
          {selectedAttributes.map((a, i) => (
            <span key={i} className="badge badge-danger mr-1 mt-1 pl-2">
              {a}
              <button type="button" className="btn btn-sm btn-link py-0" aria-label="Close" onClick={() => handleSelected(a, false)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </span>
          ))}
        </div>
      </Col>
      <Col className="py-3 bg-secondary border-left" style={{ borderRadius: "0 0.4375rem 0.4375rem 0" }}>
        <h2>
          <FormattedMessage id="datos_placa.available_templates" defaultMessage="Available templates" />
        </h2>
        <div style={{ maxHeight: 300, overflowY: "auto", paddingRight: "16px" }}>
          {templates.map((t) => (
            <div key={t.id} className="selectable-template" onClick={() => update(t)}>
              <div>{t.name}</div>
              <div>
                <div className="d-inline mr-2">
                  <small>
                    <b>
                      <FormattedMessage id="datos_placa.code" defaultMessage="code" />
                    </b>{" "}
                    {t.codeTemplate}
                  </small>
                </div>
                <div className="d-inline mr-2">
                  <small>
                    <b>
                      <FormattedMessage id="datos_placa.company" defaultMessage="company" />
                    </b>{" "}
                    {_availableCompanies[t.keyCompany].value}
                  </small>
                </div>
                <div className="d-inline mr-2">
                  <small>
                    <b>
                      <FormattedMessage id="datos_placa.type" defaultMessage="type" />
                    </b>{" "}
                    {_availableTemplatesTypes[t.type.id].name}
                  </small>
                </div>
                <div className="d-inline mr-2">
                  <small>
                    <b>
                      <FormattedMessage id="datos_placa.size" defaultMessage="size" />
                    </b>{" "}
                    {_availableTemplatesSizes[t.size.id].name}
                  </small>
                </div>
              </div>
              <hr className="my-1"></hr>
            </div>
          ))}
        </div>
      </Col>
    </Row>
  );
}

export default TemplateSearch;
