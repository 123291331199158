import React, { useState } from "react";
import { Button, Card, CardBody, CardFooter, Col, Row, Table } from "reactstrap";
import FormattedDate from "../FormattedDate";
import { FormattedMessage, useIntl } from "react-intl";
import DependentCurveTestGeneratorModal from "./DependentCurveTestGeneratorModal";
import { useService } from "../../context/ServiceContext";

function Summary(props) {
  const {
    id,
    laboratoryTestReference,
    laboratoryTestCreatedAt,
    laboratoryTestDescription,
    laboratoryTestVoltage,
    efficiencyType,
    installationType,
    vsdType,
    category,
    isSmokeExtraction,
    interpolationCoefficient,
    updatedAt,
    isPublished,
    motor,
    hasRelatedErPKits,
    onOpenRelatedErPKitSelection
  } = props;
  const intl = useIntl();
  const { curveTestService } = useService();
  const messages = {
    yes: intl.formatMessage({ id: "app.yes", defaultMessage: "Yes" }),
    no: intl.formatMessage({ id: "app.no", defaultMessage: "No" })
  };
  const [isGeneratorOpen, setIsGeneratorOpen] = useState(false);
  const [generatorAlert, setGeneratorAlert] = useState(null);
  const [generatedDependent, setGeneratedDependent] = useState(null);

  const renderDate = date => <FormattedDate value={date} />;

  const toggleGeneratorOpen = () => setIsGeneratorOpen(!isGeneratorOpen);

  const handleDependentTestChange = () => {
    setGeneratorAlert(null);
    setGeneratedDependent(null);
  }

  const handleDependentTestSubmit = async rfc => {
    try {
      setGeneratedDependent(await curveTestService.createDependentCurveTest({ id, specification: rfc }));
    } catch(e) {
      if(e.localization) {
        setGeneratorAlert(intl.formatMessage(e.localization, e.values));
      }
    }
  };

  return (
    <>
        <Card>
          <CardBody>
            <Row>
              <Col xl={8}>
                <Table bordered>
                  <thead className="thead-light">
                    <tr>
                      <th>id Ensayo</th>
                      <th>Version</th>
                      <th>Date Update</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>{id?.value ?? "?"}</td>
                      <td>{id?.version ?? "?"}</td>
                      <td>{renderDate(updatedAt)}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>

              <Col xl={4}>
                <Table bordered>
                  <thead className="thead-light">
                      <tr>
                        <th>Published</th>
                        <th>Incr.</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>{isPublished ? messages.yes : messages.no}</td>
                        <td>{interpolationCoefficient}</td>
                      </tr>
                    </tbody>
                </Table>
              </Col>
            </Row>

            <Row>
              <Col xl={7}>
                <Table bordered>
                  <thead className="thead-light">
                    <tr>
                      <th>Ensayo</th>
                      <th>Description</th>
                      <th>Fecha</th>
                      <th>Tension</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>{laboratoryTestReference}</td>
                      <td>{laboratoryTestDescription ?? "-"}</td>
                      <td>{renderDate(laboratoryTestCreatedAt)}</td>
                      <td>{laboratoryTestVoltage}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>

              <Col xl={5}>
                <Table bordered>
                  <thead className="thead-light">
                      <tr>
                        <th>Cod Modelo Motor</th>
                        <th>Potencia Motor</th>
                        <th>EF Motor</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>{motor?.id ?? "-"}</td>
                        <td>{motor?.power1 ?? "-"}</td>
                        <td>{motor?.efMotor ?? "-"}</td>
                      </tr>
                    </tbody>
                </Table>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Table bordered>
                  <thead className="thead-light">
                    <tr>
                      <th>Tipo Eficiencia</th>
                      <th>Instalacion</th>
                      <th>VSD Incluido</th>
                      <th>Categoria</th>
                      <th>Desenfumage</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>{efficiencyType}</td>
                      <td>{installationType}</td>
                      <td>{vsdType == "Included" ? intl.formatMessage({ id: "curves_generator.integrated", defaultMessage: "Integrated" }) : vsdType == "Required" ? intl.formatMessage({ id: "curves_generator.required", defaultMessage: "Required" }) : vsdType}</td>
                      <td>{category}</td>
                      <td>{isSmokeExtraction ? messages.yes : messages.no}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <div className="d-flex justify-content-end px-1">
              {hasRelatedErPKits && <Button onClick={onOpenRelatedErPKitSelection}>
                  <span><i className="fas fa-eye"></i> <FormattedMessage id="curves_generator.view_related_erp_kits" defaultMessage="View related ErP kits" /></span>
              </Button>}
              <a className="btn btn-lg btn-outline-primary" href={`/datos-placa/summary/${id.value}`}><i class="fas fa-qrcode"></i> <FormattedMessage id="curves_generator.view_plate_data" defaultMessage="View plate data" /></a>
              <Button onClick={_ => setIsGeneratorOpen(true)}>
                <span><i className="fas fa-calculator"></i> <FormattedMessage id="curves_generator.generate_dependent_idensayo" defaultMessage="Generate dependent idEnsayo" /></span>
              </Button>
            </div>
          </CardFooter>
      </Card>
      <DependentCurveTestGeneratorModal
        isOpen={isGeneratorOpen}
        toggle={toggleGeneratorOpen}
        onChange={handleDependentTestChange}
        onSubmit={handleDependentTestSubmit}
        dependent={generatedDependent}
        alert={generatorAlert} />
    </>
  );
}

export default Summary;
