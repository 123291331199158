import React from "react";
import { Container, Row, Col, Card, CardBody, CardDeck, CardFooter } from "reactstrap";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import { FormattedMessage } from "react-intl";
import SeriesProductSearch from "../../components/ProductCreation/SeriesProductSearch";

function ProductCreation(props) {
  return (
    <>
      <Header>
        <h1>
          <FormattedMessage id="products.title" defaultMessage="What you want to create in BdP?" />
        </h1>
      </Header>
      <Container fluid>
        <Row>
          <Col>
            <CardDeck>
              <Card className="disabled">
                <CardBody>
                  <Row>
                    <Col>
                      <h3 className="card-title">
                        <FormattedMessage id="products.new_range.title" defaultMessage="New range of products" />
                      </h3>
                      <p className="card-text">
                        <FormattedMessage id="products.new_range.description" defaultMessage="Click the button below if you wish to create a new range of products" />
                      </p>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                                  <Link to="/products/series/new" className="btn btn-outline-primary disabled">
                    <FormattedMessage id="app.proceed" defaultMessage="Proceed" />
                  </Link>
                  <Link to="/products/wizard/create" className="btn btn-outline-dark disabled">
                    <FormattedMessage id="app.proceed_wizard" defaultMessage="Proceed with wizard" />
                  </Link>
                </CardFooter>
              </Card>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <h3 className="card-title">
                        <FormattedMessage id="products.existing_range.title" defaultMessage="Existing range" />
                      </h3>
                      <p className="card-text">
                        <FormattedMessage
                          id="products.existing_range.description"
                          defaultMessage="Click the button below if you would like to add some products to an existing range"
                        />
                      </p>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Link to="/products/product/new/-1/0/select-series" className="btn btn-outline-primary">
                    <FormattedMessage id="app.proceed" defaultMessage="Proceed" />
                  </Link>
                  <Link to="/products/wizard/create" className="btn btn-outline-dark">
                    <FormattedMessage id="app.proceed_wizard" defaultMessage="Proceed with wizard" />
                  </Link>
                </CardFooter>
              </Card>
            </CardDeck>
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className="my-4">
              <FormattedMessage id="products.edit_view.title" defaultMessage="View / edit a product in BdP?" />
            </h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h3 className="card-title">
                      <FormattedMessage id="products.open_product.title" defaultMessage="Edit an existing product" />
                    </h3>
                    <p className="card-text">
                      <FormattedMessage id="products.open_product.description" defaultMessage="If you would like to open a product, please use the options below." />
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-3">
                    <SeriesProductSearch
                      useGeneric={true}
                      productSelectText="Select"
                      onProductSelect={(id) => props.history.push(`/products/product/${id.id}/${id.seriesId}/${id.version}/technical-information`)}
                    ></SeriesProductSearch>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ProductCreation;
