import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, FormGroup, Input, Label, CustomInput } from "reactstrap";
import { useService } from "../../../context/ServiceContext";
import { isRequired, lookupValueInAttributeOptionsById } from "../../../products/ProductDefinitions";
import AlertWithIcon from "../../AlertWithIcon";
import ListerTableSelectionModal from "../../Modals/ListerTableSelectionModal";
import ProductInput from "../ProductInput";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ProductInfo from "../ProductInfo";

function MotorParameters({ productAttributes, product, update, conceptPhase, isWizard }) {
  const intl = useIntl();
  const { motorLister } = useService();
  const [motorModalOpen, setMotorModalOpen] = useState(false);
  const isTransmission = product.conceptClassUnic?.toUpperCase() === "051A5594-081C-4B20-AFB1-73C0EB0D4E5C"; // TODO we need a better way than this
  const isReadOnly = product?.isReadOnly || false;

  const toggleMotorModal = () => setMotorModalOpen(!motorModalOpen);
  const [isGenericsOnly, setIsGenericsOnly] = useState(true);
  async function handleMotorSelection(choice) {
    if (choice) {
      console.log(choice);
      const updateProductTension = product.codTension === undefined || product.codTension === null || isNaN(product.codTension);

      let p = {
        ...product,
        codModeloMotor: choice.id,
        phase: choice.phase,
        keyModeloMotor: choice.keyModeloMotor,
        codTensionMotor: choice.codTipoTension,
        motorTension: choice.voltageT,
        motorFrequency: choice.frequency,
        motorPower: choice.power,
        motorCategory: choice.categoria,
        motorPoles: choice.poles,
      };

      if (updateProductTension) {
        p.codTension = choice.codTipoTension;
        if (p.codFrecuencia == undefined || isNaN(p.codFrecuencia)) p.codFrecuencia = choice.codFrecuencia;
        p.typeCurrent = choice.phase;
        p.polos = choice.poles;
        p.tension = choice.voltageT;
        p.frecuencia = choice.frequency;
        p.curveGroups = product.curveGroups.map((cg) => {
          return {
            ...cg,
            codTension: choice.codTipoTension,
            codFrecuencia: choice.codFrecuencia,
            tension: choice.voltage,
            frecuencia: choice.frequency,
            polos: choice.poles,
            curves: cg.curves.map((c) => {
              return {
                ...c,
                claseTension: choice.codTipoTension,
                codTension: choice.codTipoTension,
                codFrecuencia: choice.codFrecuencia,
                frecuencia: choice.frequency,
                polos: choice.poles,
              };
            }),
          };
        });
      }

      update(p);
    }
    toggleMotorModal();
  }

  function clear() {
    update({
      ...product,
      codModeloMotor: 0,
      keyModeloMotor: "",
      codTensionMotor: undefined,
      motorTension: undefined,
      motorFrequency: undefined,
      motorPower: undefined,
      motorCategory: undefined,
      motorPoles: undefined,
    });
  }

  function handleSwitchChange(e) {
    setIsGenericsOnly(e.target.checked);
  }

  return (
    <>
      <Card className="mt-2">
        <CardHeader>
          <FormattedMessage id="product_information.motor_parameters" defaultMessage="Motor parameters" />
        </CardHeader>
        <CardBody>
          <FormGroup row>
            <Label sm={3}>
              <FormattedMessage id="product_information.codModeloMotor" defaultMessage="Cod Modelo Motor" />
              {isRequired("codModeloMotor", productAttributes)}
              <ProductInfo formattedMessage={{ id: "product_information.codModeloMotor_help", defaultMessage: "product_information.codModeloMotor_help" }}></ProductInfo>
            </Label>
            <Col sm={3}>
              <Input type="text" plaintext readOnly value={product.codModeloMotor != undefined ? product.codModeloMotor : "0"} />
            </Col>
            {!isReadOnly && (
              <Col sm={6}>
                <Button className="btn btn-outline-primary" onClick={toggleMotorModal}>
                  <FormattedMessage id="app.select" defaultMessage="Select" />
                </Button>
                <Button className="btn-outline-dark" onClick={() => clear()}>
                  <FormattedMessage id="app.clear" defaultMessage="Clear" />
                </Button>
              </Col>
            )}
          </FormGroup>
          <FormGroup row>
            <ProductInput
              attributeClass="polos"
              attributes={productAttributes}
              obj={product}
              update={update}
              label="polos"
              labelMessageId="product_information.polos"
              labelProps={{ sm: 6, xl: 3 }}
              columnProps={{ sm: 6, xl: 3 }}
            ></ProductInput>
          </FormGroup>
          <FormGroup row>
            {product.keyModeloMotor && (
              <>
                <Label sm={3}>
                  <FormattedMessage id="product_information.keyModeloMotor" defaultMessage="Key Modelo Motor" />
                </Label>
                <Col sm={3}>
                  <Input type="text" plaintext readOnly value={product.keyModeloMotor || ""} />
                </Col>
              </>
            )}
            {product.motorTension && (
              <>
                <Label sm={3}>
                  <FormattedMessage id="product_information.motorTension" defaultMessage="Tension" />
                </Label>
                <Col sm={3}>
                  <Input type="text" plaintext readOnly value={product.motorTension || ""} />
                </Col>
              </>
            )}
            {product.motorFrequency && (
              <>
                <Label sm={3}>
                  <FormattedMessage id="product_information.motorFrequency" defaultMessage="Frequency" />
                </Label>
                <Col sm={3}>
                  <Input type="text" plaintext readOnly value={product.motorFrequency || ""} />
                </Col>
              </>
            )}
            {product.motorPower && (
              <>
                <Label sm={3}>
                  <FormattedMessage id="product_information.motorPower" defaultMessage="Power" />
                </Label>
                <Col sm={3}>
                  <Input type="text" plaintext readOnly value={product.motorPower || ""} />
                </Col>
              </>
            )}
            {product.motorCategory && (
              <>
                <Label sm={3}>
                  <FormattedMessage id="product_information.motorCategory" defaultMessage="Category" />
                </Label>
                <Col sm={3}>
                  <Input type="text" plaintext readOnly value={product.motorCategory || ""} />
                </Col>
              </>
            )}
            {product.motorPoles && (
              <>
                <Label sm={3}>
                  <FormattedMessage id="product_information.motorPoles" defaultMessage="Poles" />
                </Label>
                <Col sm={3}>
                  <Input type="text" plaintext readOnly value={product.motorPoles || ""} />
                </Col>
              </>
            )}
          </FormGroup>
        </CardBody>
        {product.codModeloMotor > 0 && product.codTensionMotor !== undefined && product.codTensionMotor !== product.codTension && (
          <CardFooter className="p-0 bg-danger">
            <div className="px-4 py-3 text-white">
              <span>
                <strong>
                  <FormattedMessage id="app.warning" defaultMessage="Warning" />
                </strong>{" "}
                <FormattedMessage id="product_information.voltage_different" defaultMessage="The voltage from the motor is different to the product." />
              </span>
            </div>
          </CardFooter>
        )}
        {product.codModeloMotor > 0 && conceptPhase != null && product.phase !== undefined && product.phase !== conceptPhase && (
          <CardFooter className="p-0 bg-danger">
            <div className="px-4 py-3 text-white">
              <span>
                <strong>
                  <FormattedMessage id="app.warning" defaultMessage="Warning" />
                </strong>{" "}
                <FormattedMessage id="product_information.phase_different" defaultMessage="The phase of the motor is different to the product." />
              </span>
            </div>
          </CardFooter>
        )}
      </Card>
      <ListerTableSelectionModal
        title={intl.formatMessage({ id: "app.select_a_motor", defaultMessage: "Select a motor" })}
        filterSwitch={() => (
          <>
            <Col style={{ marginBottom: "-24px", marginTop: "15px" }}>
              <CustomInput
                type="switch"
                label={intl.formatMessage({ id: "motors.genericsonly", defaultMessage: "Generics only" })}
                checked={isGenericsOnly}
                onChange={(e) => handleSwitchChange(e)}
                id="motors.genericsonly"
              />
            </Col>
          </>
        )}
        columns={[
          { Header: intl.formatMessage({ id: "motors.cod_modelo_motor", defaultMessage: "CodModeloMotor" }), accessor: "id" },
          { Header: intl.formatMessage({ id: "motors.potencia", defaultMessage: "Power" }), accessor: "power" },
          { Header: intl.formatMessage({ id: "motors.frequency", defaultMessage: "Frequency" }), accessor: "frequency" },
          { Header: intl.formatMessage({ id: "motors.voltage", defaultMessage: "Voltage" }), accessor: "voltage" },
          { Header: intl.formatMessage({ id: "motors.poles", defaultMessage: "Poles" }), accessor: "poles" },
          { Header: intl.formatMessage({ id: "motors.frame_standard", defaultMessage: "Frame Std." }), accessor: "frameStandard" },
          { Header: intl.formatMessage({ id: "motors.efficiency_standard", defaultMessage: "Efficiency Std." }), accessor: "efficiencyStandard" },
          { Header: intl.formatMessage({ id: "motors.transmision", defaultMessage: "Transmision" }), accessor: "transmision" },
          { Header: intl.formatMessage({ id: "motors.flagPV_IEC", defaultMessage: "flagPV_IEC" }), accessor: "flagPV_IEC" },
          { Header: intl.formatMessage({ id: "motors.categoria", defaultMessage: "Categoria" }), accessor: "categoria" },
          { Header: intl.formatMessage({ id: "motors.brand_description", defaultMessage: "Marca" }), accessor: "marcaDescripcion" },
        ]}
        prefilter={{ isgenericsonly: isGenericsOnly }}
        isOpen={motorModalOpen}
        toggle={toggleMotorModal}
        lister={motorLister}
        initialFilter={{ transmision: isTransmission ? 1 : undefined }}
        onSelect={handleMotorSelection}
      />
    </>
  );
}

export default MotorParameters;
