import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from "reactstrap";
import ListerTable from "../Tables/ListerTable";
import { useService } from "../../context/ServiceContext";
import MultiSearchPanel from "../Search/MultiSearchPanel";
import useHistoryState from "../../utils/useHistoryState";

function CurveTestSearch({ onCurveTestSelect }) {
  const intl = useIntl();
  const { curveTestLister, curveLister, curveLabTestNumberSuggester, curveTestIdSuggester } = useService();
  const [submittedSearch, setSubmittedSearch] = useHistoryState("CurveTestSearch.Submitted");
  const [filter, setFilter] = useHistoryState("CurveTestSearch.Filter");
  const [page, setPage] = useHistoryState("CurveTestSearch.Page");

  const searchFields = [
    {
      id: "lab_test_number",
      label: intl.formatMessage({
        id: "tests.lab_test_number",
        defaultMessage: "Lab test number",
      }),
      value: "",
      suggester: curveLabTestNumberSuggester,
    },
    {
      id: "test_id",
      label: intl.formatMessage({
        id: "curves_generator.curve_test_id",
        defaultMessage: "ID ensayo",
      }),
      value: "",
      suggester: curveTestIdSuggester,
    },
  ];

  const searchHandles = {
    lab_test_number: {
      lister: curveLister,
      step: "test",
      filter: (value) => ({ labTestNumber: value }),
    },
    test_id: {
      lister: curveLister,
      step: "test",
      filter: (value) => ({ curveTestId: parseInt(value) }),
    },
  };

  function handleSearchSubmit(search) {
    if (search !== null) {
      let handle = searchHandles[search.id];
      setSubmittedSearch({
        step: handle.step,
        filter: handle.filter(search.value),
      });
    }
  }

  function renderSmallTableHeader({ column, children }) {
    return (
      <th {...column.getHeaderProps()} style={{ width: "1%" }}>
        {children}
      </th>
    );
  }

  return (
    <>
      <Row>
        <Col>
          <MultiSearchPanel fields={searchFields} onSubmit={handleSearchSubmit} />
        </Col>
      </Row>
      {submittedSearch && (
        <Row className="mt-3">
          <Col>
            <Card>
              <CardHeader>Results</CardHeader>
              <CardBody>
                <ListerTable
                  className={"align-items-center table-flush"}
                  columns={[
                    { id: "icon", TableHeader: renderSmallTableHeader, Cell: () => <i className="fas fa-flask"></i> },
                    { Header: intl.formatMessage({ id: "curve_tests.curve_test_id", defaultMessage: "ID ensayo" }), accessor: "curveTestId" },
                    { Header: intl.formatMessage({ id: "curve_tests.lab_test_number", defaultMessage: "Lab test number" }), accessor: "labTestNumber" },
                    { Header: intl.formatMessage({ id: "curve_tests.modelo_curva", defaultMessage: "Modelo curva" }), accessor: "modeloCurva" },
                    { Header: intl.formatMessage({ id: "curve_tests.efficiency_type", defaultMessage: "Eff. type" }), accessor: "efficiencyType" },
                    { Header: intl.formatMessage({ id: "curve_tests.intallation_type", defaultMessage: "Install type" }), accessor: "installationType" },
                    {
                      id: "Published",
                      Header: intl.formatMessage({ id: "curve_tests.is_published", defaultMessage: "Is published?" }),
                      Cell: (cell) =>
                        cell.row.original.isPublished ? <FormattedMessage id="app.yes" defaultMessage="Yes" /> : <FormattedMessage id="app.no" defaultMessage="No" />,
                    },
                    {
                      id: "select",
                      TableHeader: renderSmallTableHeader,
                      Cell: ({ cell }) => (
                        <Button type="button" className="btn btn-outline-primary float-right" onClick={() => onCurveTestSelect(cell.row.original.curveTestId)}>
                          <FormattedMessage id="app.select" defaultMessage="Select" />
                        </Button>
                      ),
                    },
                  ]}
                  lister={curveTestLister}
                  prefilter={submittedSearch?.filter}
                  initialFilter={filter}
                  onFilterChange={setFilter}
                  initialPage={page}
                  onPageChange={setPage}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
}

export default CurveTestSearch;
