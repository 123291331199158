import React from "react";
import { ValidationForm } from "react-bootstrap4-form-validation";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Card, CardBody, CardFooter, CardHeader, Input } from "reactstrap";

function ConceptClassStep({ next, prev, conceptClassUnic, setConceptClass, setConceptClassCurve, conceptClasses }) {
  const intl = useIntl();

  return (
    <ValidationForm onSubmit={() => {}}>
      <Card className="mt-2">
        <CardHeader>
          <FormattedMessage id="product_wizard.product_denomination" defaultMessage="Product denomination" />
        </CardHeader>
        <CardBody>
          <p>
            <FormattedMessage id="product_wizard.select_concept_class" defaultMessage="Select the ConceptClass that fits better with the products you are adding:" />
          </p>
          <Input
            type="select"
            id="conceptClassUnic"
            value={conceptClassUnic}
            onChange={(e) => {
              setConceptClass(e.target.value);
              setConceptClassCurve(e.target.value);
            }}
            style={{ maxWidth: 450 }}
          >
            <option value="" disabled="disabled">
              {intl.formatMessage({ id: "product_information.select_an_option", defaultMessage: "Please select an option" })}
            </option>
            {conceptClasses.map((category) => (
              <option key={category.id} value={category.id}>
                {category.value}
              </option>
            ))}
          </Input>
        </CardBody>
        <CardFooter>
          <Button className="btn btn-outline-dark float-right" onClick={next}>
            <FormattedMessage id="app.next" defaultMessage="Next" />
          </Button>
          <Button className="btn btn-outline-danger" onClick={prev}>
            <i className="fas fa-arrow-left"></i> <FormattedMessage id="app.back" defaultMessage="Back" />
          </Button>
        </CardFooter>
      </Card>
    </ValidationForm>
  );
}

export default ConceptClassStep;
