import React from "react";
import { Row, Col, CustomInput } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import SubmitButton from "../../SubmitButton";
import { useVuErP } from "../../../context/curves/VuErPContext";

function ErPKitViewerHeader({ value }) {
  const {
    isSaving,
    isSaved,
    fields: { useEcCalculations, setUseEcCalculations, active, setActive, useManualCalculation, setUseManualCalculation },
  } = useVuErP();
  const intl = useIntl();

  return (
    <>
      <Row className="align-items-center">
        <Col className="col-auto">
          <span className="mr-3">
            <b>
              <FormattedMessage id="curve_generator.modelo_kit_erp" defaultMessage="Modelo kit ErP" />
            </b>
          </span>
          <span>{value?.details?.name ?? "-"}</span>
        </Col>
        <Col className="col-auto">
          <span className="mr-3">
            <b>
              <FormattedMessage id="curve_generator.erp_type" defaultMessage="ErP form type" />
            </b>
          </span>
          <span>{`${value.isResidential ? "RVU" : "NRVU"}`}</span>
        </Col>
        {setUseEcCalculations && (
          <Col className="col-auto">
            <CustomInput
              type="switch"
              id="ignore-ec"
              label={intl.formatMessage({ id: "curve_generator.use_ec_calculations", defaultMessage: "Use EC calculations" })}
              checked={useEcCalculations}
              onChange={(e) => setUseEcCalculations(e.target.checked)}
            />
          </Col>
        )}

        {setUseEcCalculations && (
          <Col className="col-auto">
            <CustomInput
              type="switch"
              id="manual-calc"
              label={intl.formatMessage({ id: "curve_generator.use_manual_calculations", defaultMessage: "Use manual calculations" })}
              checked={useManualCalculation}
              onChange={(e) => setUseManualCalculation(e.target.checked)}
            />
          </Col>
        )}

        <Col className="col-auto">
          <CustomInput
            type="switch"
            id="is-active"
            label={intl.formatMessage({ id: "curve_generator.active", defaultMessage: "Active" })}
            checked={active}
            onChange={(e) => setActive(e.target.checked)}
          />
        </Col>
        <Col className="col-auto ml-auto">
          <SubmitButton type="submit" className="btn btn-outline-primary" submitting={isSaving ? 1 : 0} submitted={isSaved ? 1 : 0}></SubmitButton>
        </Col>
      </Row>
    </>
  );
}

export default ErPKitViewerHeader;
