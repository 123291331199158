import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, CardDeck, CardFooter, Button } from "reactstrap";
import Header from "../../components/Header";
import ListerTable from "../../components/Tables/ListerTable";
import { FormattedMessage, useIntl } from "react-intl";
import { useService } from "../../context/ServiceContext";
import { useConfig } from "../../context/ConfigContext";

function LaboratoryTests() {
  const intl = useIntl();
  const limsRoot = useConfig("lims.root");

  const { aeroTestLister } = useService();

  return (
    <>
      <Header>
        <h1>
          <FormattedMessage id="laboratory_tests.title" defaultMessage="Please select a laboratory test to view" />
        </h1>
      </Header>
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <FormattedMessage id="laboratory_tests.table_title" defaultMessage="Laboratory tests" />
                </div>
              </CardHeader>
              <CardBody className="p-0">
                <ListerTable
                  className={"align-items-center table-flush table-sm"}
                  columns={[
                    {
                      Header: intl.formatMessage({ id: "tests.lab_name", defaultMessage: "Lab name" }),
                      accessor: "laboratoryName",
                    },
                    {
                      Header: intl.formatMessage({ id: "tests.lab_test_number", defaultMessage: "Lab test number" }),
                      accessor: "testNumber",
                    },
                    {
                      Header: intl.formatMessage({ id: "tests.request_number", defaultMessage: "Request number" }),
                      accessor: "testRequestNumber",
                    },
                    {
                      Header: intl.formatMessage({ id: "tests.brand", defaultMessage: "Brand" }),
                      accessor: "brandName",
                    },
                    {
                      Header: intl.formatMessage({ id: "tests.model", defaultMessage: "Model" }),
                      accessor: "productModelName",
                    },
                    {
                      Header: intl.formatMessage({ id: "tests.project_name", defaultMessage: "Project name" }),
                      accessor: "projectName",
                    },
                    {
                      id: "view",
                      Cell: ({ cell }) => (
                        <a className="btn btn-outline-primary" href={limsRoot + "/Reports/Aerodynamic/View/" + cell.row.original.id} target="_blank">
                          <FormattedMessage id="app.view" defaultMessage="View" />
                        </a>
                      ),
                    },
                  ]}
                  lister={aeroTestLister}
                  pageSize={10}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default LaboratoryTests;
