import React, { useState, useEffect, Fragment } from "react";
import { Button } from "reactstrap";
import { Wizard, Step, Steps } from "react-albus";
import { FormattedMessage, useIntl } from "react-intl";
import ListerTable from "../Tables/ListerTable";
import { useGetLatest } from "react-table";
import ExpanderCell from "../Tables/ExpanderCell";
import { useService } from "../../context/ServiceContext";

function SeriesProductWizard({
  listers,
  search,
  onProductSelect,
  productSelectText,
  onSeriesSelect,
  seriesSelectText,
  //onSeriesSAPSelect,
  //seriesSAPSelectText,
  onNoResults,
  useGeneric,
}) {
  const intl = useIntl();
  const [history, setHistory] = useState([]);
  const [step, setStep] = useState();

  useEffect(() => {
    loadStep({ step: search.step, filter: search.filter }).then(() => {
      setHistory([{ step: search.step, filter: { ...search.filter } }]);
    });
  }, [search]);

  function getPrefilter() {
    var entry = history[history.length - 1];
    return entry.filter;
  }

  async function loadStep(entry) {
    setStep({ id: entry.step });
    setHistory(history.concat(entry));
  }

  async function handleNavigateBack() {
    var entry = history[history.length - 2];
    setStep({ id: entry.step });
    setHistory(history.slice(0, history.length - 1));
  }

  function handleProductSelection(value) {
    if (onProductSelect) {
      onProductSelect(value);
    }
  }

  function handleSeriesSelection(value) {
    if (onSeriesSelect) {
      onSeriesSelect(value);
    }
  }

  // function handleSeriesSAPSelection(value) {
  //   if (onSeriesSAPSelect) {
  //     onSeriesSAPSelect(value);
  //   }
  // }

  function renderSmallTableHeader({ column, children }) {
    return (
      <th {...column.getHeaderProps()} style={{ width: "1%" }}>
        {children}
      </th>
    );
  }

  if (step) {
    let seriesColumns = [
      { id: "icon", TableHeader: renderSmallTableHeader, Cell: () => <i className="fas fa-boxes"></i> },
      //{ Header: intl.formatMessage({ id: "curves_generator.series_id", defaultMessage: "Series id" }), accessor: "id" },
      //{ Header: intl.formatMessage({ id: "product_wizard.tipo_curva", defaultMessage: "Tipo Curva" }), accessor: "tipoCurva" },
      { Header: intl.formatMessage({ id: "curves_generator.series_name", defaultMessage: "Series name" }), accessor: "internalName" },
      { Header: intl.formatMessage({ id: "curves_generator.series_short_name", defaultMessage: "Series short name" }), accessor: "name" },
    ];

    if (!useGeneric) {
      seriesColumns.push({ Header: intl.formatMessage({ id: "datos_placa.series_id", defaultMessage: "Series ID" }), accessor: "serieId" });
      seriesColumns.push({ Header: intl.formatMessage({ id: "datos_placa.series_description", defaultMessage: "Series Description" }), accessor: "serieDescription" });
    }

    seriesColumns.push({
      id: "showProducts",
      TableHeader: renderSmallTableHeader,
      Cell: ({ cell }) => (
        <Button className="btn btn-outline-dark float-right" onClick={() => loadStep({ step: "product", filter: { seriesId: cell.row.original.id } })}>
          <FormattedMessage id="product_wizard.show_products" defaultMessage="Show products" />
        </Button>
      ),
    });

    if (onSeriesSelect) {
      seriesColumns.push({
        id: "select",
        TableHeader: renderSmallTableHeader,
        Cell: ({ cell }) => (
          <Button className="btn btn-outline-danger float-right" onClick={() => handleSeriesSelection(cell.row.original)}>
            {seriesSelectText}
          </Button>
        ),
      });
    }

    let productColumns = [{ id: "expander", TableHeader: renderSmallTableHeader, Cell: ExpanderCell, Header: () => null }];

    if (useGeneric) {
      productColumns.push({ Header: intl.formatMessage({ id: "curves_generator.product_code", defaultMessage: "Product code" }), accessor: "id" });
      productColumns.push({ Header: intl.formatMessage({ id: "curves_generator.product_name", defaultMessage: "Product name" }), accessor: "name" });
      productColumns.push({ Header: intl.formatMessage({ id: "curves_generator.product_version", defaultMessage: "Product version" }), accessor: "version" });
    } else {
      productColumns.push({ Header: intl.formatMessage({ id: "curves_generator.product_comercial_code", defaultMessage: "Commercial code" }), accessor: "codProductoComercial" });
      productColumns.push({
        Header: intl.formatMessage({ id: "curves_generator.product_comercial_description", defaultMessage: "Commercial description" }),
        accessor: "productoComercial",
      });
    }

    productColumns.push({
      id: "showSeries",
      TableHeader: renderSmallTableHeader,
      Cell: ({ cell }) => (
        <Button
          className="btn btn-outline-dark float-right"
          onClick={() => {
            console.log("ss");
            loadStep({ step: "series", filter: { id: cell.row.original.seriesId } });
          }}
        >
          <FormattedMessage id="product_wizard.show_series" defaultMessage="Show series" />
        </Button>
      ),
    });

    productColumns.push({
      id: "select",
      TableHeader: renderSmallTableHeader,
      Cell: ({ cell }) => (
        <Button className="btn btn-outline-danger float-right" onClick={() => handleProductSelection(cell.row.original)}>
          {productSelectText}
        </Button>
      ),
    });

    return (
      <Wizard>
        <Steps step={step}>
          <Step
            id="series"
            render={({ next }) => <ListerTable className={"align-items-center table-flush"} columns={seriesColumns} lister={listers.series} prefilter={getPrefilter()} />}
          />

          <Step
            id="product"
            render={({ next }) => (
              <ListerTable
                className={"align-items-center table-flush"}
                columns={productColumns}
                lister={listers.product}
                initialFilter={getPrefilter()}
                onNoResults={onNoResults}
                renderSubComponent={(item) => {
                  return <CurveGroupDetails id={item.row.original.id} />;
                }}
              />
            )}
          />
        </Steps>

        {history.length > 1 ? (
          <div className="px-3 pb-3">
            <Button className={"btn-outline-danger btn-md mt-4"} onClick={handleNavigateBack}>
              <i className="fas fa-arrow-left"></i> <FormattedMessage id="app.back" defaultMessage="Back" />
            </Button>
          </div>
        ) : (
          <></>
        )}
      </Wizard>
    );
  } else {
    return <></>;
  }
}

function CurveGroupDetails({ id }) {
  const [curveGroups, setCurveGroups] = useState([]);
  const { curveGroupDetailsProvider } = useService();

  async function getCurveGroups() {
    if (!id) return;
    setCurveGroups(await curveGroupDetailsProvider.get(id));
  }

  useEffect(() => {
    getCurveGroups();
  }, [id]);

  return (
    <table className="table align-items-center">
      <thead className="thead-light">
        <tr>
          <th style={{ width: "1%" }}></th>
          <th>
            <FormattedMessage id="product_summary.groups" defaultMessage="Groups" />
          </th>
        </tr>
      </thead>
      <tbody>
        {curveGroups.map((cg, i) => {
          return (
            <Fragment key={i}>
              <tr>
                <td></td>
                <td>
                  {i + 1}. {cg.group}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <table className="table align-items-center">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "1%" }}></th>
                        <th style={{ width: "50px" }}></th>
                        <th>
                          <FormattedMessage id="product_summary.curves" defaultMessage="Curves" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {cg.curves.map((c, j) => {
                        return (
                          <tr key={j}>
                            <td></td>
                            <td></td>
                            <td>
                              {j + 1}. {c}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </td>
              </tr>
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
}

export default SeriesProductWizard;
