import React, { useEffect, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Table,
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  CustomInput,
} from "reactstrap";
import Input from "../../Input";
import FormattedNumber from "../../FormattedNumber";
import SoundPowerTest from "../../../acoustic/SoundPowerTest";
import SoundPower, { OperatingPoint } from "../../../acoustic/SoundPower";
import SoundPowerCorrection from "../../../acoustic/SoundPowerCorrection";
import SoundSample from "../../../acoustic/SoundSample";
import DefaultAggregateRegion from "../../../acoustic/DefaultAggregateRegion";
import RadiatedAggregateRegion from "../../../acoustic/RadiatedAggregateRegion";
import { useService } from "../../../context/ServiceContext";
import { useState } from "react";
import ListerTableSelectionModal from "../../Modals/ListerTableSelectionModal";
import Tooltip from "../../Tooltip";
import Correction from "../../../acoustic/Correction";
import SubmitButton from "../../SubmitButton";
import { fireAlert } from "../../../utils/alerts";
import ErrorAlertContent from "../../ErrorAlertContent";
import DependentAcousticTestGeneratorModal from "./DependentAcousticTestGeneratorModal";
import { set, get, some } from "lodash";
import { ExportToExcel } from "../../../acoustic/ExcelExport";

const decibelNumberOptions = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  useGrouping: false,
};

const AcousticTypes = {
  inlet: "A",
  outlet: "D",
  radiated: "T,R"
};

const cellStyle = { padding: "0.5rem" };

const frequencies = [63, 125, 250, 500, 1000, 2000, 4000, 8000];

function AcousticEntry(props) {
  const intl = useIntl();
  const { curveLister, acousticTestLister, aggregateTotalService } = useService();
  const [curveModalOpen, setCurveModalOpen] = useState(false);
  const [acousticTestModalOpen, setAcousticTestModalOpen] = useState(false);
  const [curve, setCurve] = useState(null);
  const [distance, setDistance] = useState();
  const [directivity, setDirectivity] = useState();
  const [manualEntry, setManualEntry] = useState();
  const [correctionValue, setCorrectionValue] = useState();
  const [acousticPrefilter, setAcousticPrefilter] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isGeneratorOpen, setIsGeneratorOpen] = useState(false);
  const [generatorAlert, setGeneratorAlert] = useState(null);
  const [generatedDependent, setGeneratedDependent] = useState(null);
  const [, setForceUpdate] = useState(Date.now());

  const regions = useRef([]);
  const correction = useRef(new Correction());
  const acousticReplacement = useRef();

  const forceRender = () => {
    setForceUpdate(Date.now());
  };

  const toggleGeneratorOpen = () => setIsGeneratorOpen(!isGeneratorOpen);

  const handleDependentTestChange = () => {
    setGeneratorAlert(null);
    setGeneratedDependent(null);
  };

  const handleDependentTestSubmit = async (rfc) => {
    try {
      setGeneratedDependent(await aggregateTotalService.createDependentNoiseTest({ curveId: curve.id, specification: rfc }));
    } catch (e) {
      if (e.localization) {
        setGeneratorAlert(intl.formatMessage(e.localization, e.values));
      }
    }
  };

  const toggleCurveModal = () => setCurveModalOpen(!curveModalOpen);
  const toggleAcousticTestModal = () => setAcousticTestModalOpen(!acousticTestModalOpen);

  useEffect(() => {
    correction.current.distance = distance;
    updateValidity();
  }, [distance]);

  useEffect(() => {
    correction.current.directivity = directivity;
    updateValidity();
  }, [directivity]);

  useEffect(() => {
    updateValidity();
  }, [manualEntry]);

  useEffect(() => {
    correction.current.on("change", () => setCorrectionValue(correction.current.value));
  }, []);

  function updateValidity() {
    //setIsValid(directivity && distance && regions.current?.length && regions.current.every((r) => r.tests.every((t) => !!t.reference)));
    setIsValid(
      // directivity && distance && regions.current?.length && regions.current.every((r) => (r.key == "inlet" && !manualEntry ? r.tests.findIndex((t) => !!t.reference) > -1 : true))
      //_.some(collection, [predicate])
      directivity &&
        distance &&
        regions.current?.length &&
        some(regions.current, (r) => r.tests.findIndex((t) => (!manualEntry ? !!t.reference : t.soundPower.samples.findIndex((s) => s.soundLevel.value > 0) > -1)) > -1)
    );
  }

  function renderAcousticReference(test, region, index) {
    if (test.reference === null) {
      return renderAcousticSearch(region, index);
    } else if (test.reference) {
      return (
        <Container>
          <Row>
            <Col xs={8} className="text-truncate">
              <span>{test.reference}</span>
            </Col>
            <Col xs={4} style={{ display: "flex" }}>
              {/* <Tooltip text={intl.formatMessage({ id: "app.switch", defaultMessage: "Switch" })}>
                <Button className="btn-sm btn-danger my-n1" onClick={() => handleAcousticReplacement(region, index)} disabled={isSaving}>
                  <i className="fas fa-exchange-alt"></i>
                </Button>
              </Tooltip>
              <Tooltip text={intl.formatMessage({ id: "app.switch", defaultMessage: "Switch" })}>
                <i className="fas fa-times-circle text-danger" style={{ fontSize: "20px" }}></i>
              </Tooltip> */}

              <UncontrolledButtonDropdown>
                <DropdownToggle caret className="btn-sm bg-danger text-white">
                  <i className="fas fa-user-edit text-white"></i>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={() => handleAcousticReplacement(region, index)} disabled={isSaving}>
                    <a className="text-decoration-none text-reset">
                      <i className="fas fa-exchange-alt text-danger" style={{ fontSize: "15px" }}></i> Change
                    </a>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      handleAcousticRemovement(
                        regions.current.findIndex((r) => r.key == region.key),
                        index
                      )
                    }
                  >
                    <a className="text-decoration-none text-reset">
                      {" "}
                      <i className="fas fa-times-circle text-danger" style={{ fontSize: "15px" }}></i> Remove
                    </a>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </Col>
          </Row>
        </Container>
      );
    } else {
      return <></>;
    }
  }

  function renderAcousticSearch(region, index) {
    return (
      <Tooltip text={intl.formatMessage({ id: "app.search", defaultMessage: "Search" })}>
        <Button className="btn-sm btn-outline-danger my-n1" onClick={() => handleAcousticReplacement(region, index)}>
          <i className="fas fa-search"></i>
        </Button>
      </Tooltip>
    );
  }

  async function handleCurveSelection(choice) {
    toggleCurveModal();

    let origin = await aggregateTotalService.getCurveTemplate(choice.id);
    let inlet;
    regions.current = origin.regions.map((r) => {
      const tests = r.tests.map((t) =>
        !t
          ? null
          : new SoundPowerTest(
              t.acousticReference,
              t.testIdUnique,
              new SoundPower(
                correction.current,
                t.operatingPoint,
                t.samples.map((s) => new SoundSample(s))
              )
            )
      );

      //if (r.key === "radiated") {
        //return new RadiatedAggregateRegion(r.key, tests, inlet);
      //} else {
        const region = new DefaultAggregateRegion(r.key, tests);
        if (r.key === "inlet") {
          inlet = region;
        }

        return region;
      //}
    });

    setCurve(choice);
  }

  function handleAcousticReplacement(region, index) {
    acousticReplacement.current = { region, index };
    setAcousticPrefilter({ acousticTestType: AcousticTypes[region.key] });
    toggleAcousticTestModal();
  }

  function handleAcousticRemovement(regionIndex, itemIndex) {
    const region = regions.current[regionIndex];

    if (region) {
      let test = region.tests[itemIndex];
      test.reference = null;
      test.soundPower.operatingPoint = null;
      test.soundPower.samples = [];
      test.id = null;
    }

    acousticReplacement.current = null;
    updateValidity();
    forceRender();
  }

  function clearCurveSelection() {
    regions.current = [];
    setCurve(null);
    setIsValid(false);
  }

  async function handleAcousticTestSelection(choice) {
    const data = await aggregateTotalService.getAcousticAggregateSummary(choice.testNumber, choice.id);
    const region = acousticReplacement.current.region;

    if (region) {
      let test = region.tests[acousticReplacement.current.index];
      test.reference = data.acousticReference;
      test.soundPower.operatingPoint = data.operatingPoint;
      test.soundPower.samples = data.samples.map((s) => new SoundSample(s));
      test.id = data.testIdUnique;
    }

    acousticReplacement.current = null;
    updateValidity();
    toggleAcousticTestModal();
  }

  async function handleSaveClick() {
    setIsSaving(true);
    try {
      manualEntry
        ? await aggregateTotalService.createAggregateNoiseTotalManual({
            curveId: curve.id,
            correction: correction.current.value,
            manualAcousticEntries: regions.current.flatMap((r) =>
              r.tests.map((t) => ({
                testIdUnique: t.id,
                acousticReference: t.reference,
                operatingPoint: t.soundPower.operatingPoint,
                samples: t.soundPower.samples.map((s) => ({ frequency: s.frequency, soundLevel: s.soundLevel.value })),
                noiseType: t.key,
              }))
            ),
          })
        : await aggregateTotalService.createAggregateNoiseTotal({
            curveId: curve.id,
            correction: correction.current.value,
            acousticReferences: regions.current.flatMap((r) => r.tests.map((t) => ({ key: t.reference, value: t.id }))),
            maxAirflowTest: Math.max(...regions.current.flatMap((r) => r.tests.map((t) => (t.soundPower.operatingPoint ? t.soundPower.operatingPoint.airflow : 0)))) 
          });

      setIsSaved(true);
    } catch (e) {
      if (e.localization) {
        fireAlert(
          <ErrorAlertContent message={intl.formatMessage(e.localization, e.values)} traceId={e.traceId} />,
          intl.formatMessage({ id: "app.error", defaultMessage: "Error" }),
          "error"
        );
      }
    } finally {
      setIsSaving(false);
    }
  }

  function onManualEntryChange(regionIndex, index, property, value) {
    if (regionIndex > -1) {
      set(regions.current[regionIndex], `tests[${index}].${property}`, value);
    }
  }

  function onManualEntrySampleChange(regionIndex, index, property, value) {
    if (regionIndex > -1) {
      if (regions.current[regionIndex].tests[index].soundPower.samples.length == 0) {
        regions.current[regionIndex].tests[index].soundPower.samples = regions.current[regionIndex].tests[index].soundPower.newSamples();
      }
      console.log(get(regions.current[regionIndex], `tests[${index}].${property}`));
      set(regions.current[regionIndex], `tests[${index}].${property}`, value);
    }
    forceRender();
    updateValidity();
  }

  function onExportAcousticClick() {
    let excelSoundData = [];
    regions.current.forEach((r) => {
      r.items.forEach((i) => {
        if (i instanceof SoundPowerTest) {
          let sp = i.soundPower;
          if (sp.samples.findIndex((s) => s.soundLevel.value > 0) > -1) {
            excelSoundData.push({
              Tests: r.key,
              Airflow: sp.operatingPoint?.airflow,
              Pressure: sp.operatingPoint?.pressure,
              Speed: sp.operatingPoint?.speed,
              Hz_63: sp.sampleAt(63)?.soundLevel.value,
              Hz_125: sp.sampleAt(125)?.soundLevel.value,
              Hz_250: sp.sampleAt(250)?.soundLevel.value,
              Hz_500: sp.sampleAt(500)?.soundLevel.value,
              Hz_1000: sp.sampleAt(1000)?.soundLevel.value,
              Hz_2000: sp.sampleAt(2000)?.soundLevel.value,
              Hz_4000: sp.sampleAt(4000)?.soundLevel.value,
              Hz_8000: sp.sampleAt(8000)?.soundLevel.value,
              LWA: sp.lwA?.value,
              LPA: sp.lpA?.value,
            });
          }
        }
      });
    });
    ExportToExcel({ excelData: excelSoundData, fileName: "Acoustics " + curve?.name });
  }

  return (
    <>
      <Card className="mb-3">
        <CardHeader>
          <FormattedMessage id="acoustic_aggregate.input_data" defaultMessage="Input data" />
        </CardHeader>

        <CardBody>
          <Row>
            <Col>
              <FormGroup className="mb-0">
                <Label>
                  <FormattedMessage id="acoustic_aggregate.curve" defaultMessage="Modelo curva" />
                </Label>
                <Row>
                  <Col sm={12}>
                    <Row>
                      {curve ? (
                        <>
                          <Col sm={9}>
                            <Input type="text" plaintext readOnly value={curve?.name || "-"} />
                          </Col>
                          <Col sm={3} className="text-left">
                            <Tooltip text={intl.formatMessage({ id: "app.clear", defaultMessage: "Clear" })}>
                              <i className="fas fa-times-circle text-danger" style={{ padding: "0.625rem 0" }} onClick={clearCurveSelection}></i>
                            </Tooltip>
                          </Col>
                        </>
                      ) : (
                        <Col>
                          <Button className="btn-outline-primary" onClick={toggleCurveModal}>
                            <FormattedMessage id="app.select" defaultMessage="Select" />
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </FormGroup>
            </Col>

            {curve && (
              <Col>
                <FormGroup className="mb-0">
                  <Label>
                    {" "}
                    <FormattedMessage id="acoustic_aggregate.manual_entry" defaultMessage="Manual entry" />
                  </Label>
                  <CustomInput
                    onChange={() =>
                      setManualEntry((preValue) => {
                        return !preValue;
                      })
                    }
                    type="switch"
                    visible={curve != null}
                    //disabled = {}
                    //label={intl.formatMessage({ id: "curves_generator.smoke_extraction", defaultMessage: "Smoke extraction" })}
                    //checked={}
                    //onChange={(e) => onChangeIsSmokeExtraction(e.target.checked)}
                    id="desenfumage"
                  />
                </FormGroup>
              </Col>
            )}

            <Col>
              <FormGroup className="mb-0">
                <Label>
                  <FormattedMessage id="acoustic_aggregate.directivity" defaultMessage="Directivity" />
                </Label>
                <Input type="number" step="1" className="w-75" value={directivity} disabled={isSaving} onValueChange={(values) => setDirectivity(values.floatValue)} />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup className="mb-0">
                <Label>
                  <FormattedMessage id="acoustic_aggregate.distance" defaultMessage="Distance" />
                </Label>
                <Input type="number" step="1" className="w-75" value={distance} disabled={isSaving} onValueChange={(values) => setDistance(values.floatValue)} />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup className="mb-0">
                <Label>
                  <FormattedMessage id="acoustic_aggregate.correction" defaultMessage="Correction" />
                </Label>
                <p className="mt-2">
                  <FormattedNumber value={correctionValue} options={{ maximumFractionDigits: 1 }} />
                </p>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          <FormattedMessage id="acoustic_aggregate.calculations" defaultMessage="Calculations" />
          {isSaved && (
            <Col className="w-auto float-right">
              <div className="d-flex justify-content-end">
                <UncontrolledButtonDropdown>
                  <DropdownToggle caret>
                    <i className="fas fa-cog"></i>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={onExportAcousticClick}>
                      <a className="text-decoration-none text-reset">
                        <i class="fas fa-file-excel"></i> <FormattedMessage id="curves_generator.export_to_excel" defaultMessage="Export to Excel" />
                      </a>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
            </Col>
          )}
        </CardHeader>

        <CardBody>
          {curve ? (
            <>
              <div className="table-responsive">
                <Table>
                  <thead>
                    <tr>
                      <th style={cellStyle}>
                        <FormattedMessage id="acoustic_aggregate.tests" defaultMessage="Tests" />
                      </th>
                      <th style={cellStyle} colSpan="3">
                        <FormattedMessage id="acoustic_aggregate.operating_point" defaultMessage="Operating point" />
                      </th>
                      {frequencies.map((f, i) => (
                        <th key={i} style={cellStyle}>
                          {f} Hz
                        </th>
                      ))}
                      <th style={cellStyle}>LwA</th>
                      <th style={cellStyle}>LpA</th>
                      <th style={cellStyle}>LpA Corrections</th>
                    </tr>
                  </thead>

                  <tbody>
                    {regions.current?.map((r, regionIndex) =>
                      r.items.map((v, i) => {
                        const soundPower = v?.soundPower ?? v;

                        return (
                          <>
                            {i === 0 && (
                              <tr key={`${r.key}-${i}`}>
                                <th colSpan={14}>
                                  <FormattedMessage id={`acoustic_aggregate.${r.key}`} defaultMessage={r.key} />
                                </th>
                              </tr>
                            )}

                            <tr key={`${r.key}-${i + 1}`}>
                              <td>
                                {v instanceof SoundPowerCorrection ? (
                                  <strong>
                                    <FormattedMessage id="acoustic_aggregate.corrections" defaultMessage="Corrections" />
                                  </strong>
                                ) : (
                                  renderAcousticReference(v, r, i)
                                )}
                              </td>
                              <td>
                                {manualEntry && !(v instanceof SoundPowerCorrection) && i <= 2 ? (
                                  <Input
                                    type="number"
                                    step="1"
                                    className="form-control-sm d-inline w-50"
                                    value={soundPower?.operatingPoint?.pressure}
                                    disabled={isSaving || isSaved}
                                    onValueChange={(values) => onManualEntryChange(regionIndex, i, "soundPower.operatingPoint.pressure", values.floatValue)}
                                  />
                                ) : (
                                  soundPower?.operatingPoint?.pressure
                                )}{" "}
                                {typeof soundPower?.operatingPoint?.pressure === "number" || (manualEntry && "Pa")}
                              </td>
                              <td>
                                {manualEntry && !(v instanceof SoundPowerCorrection) && i <= 2 ? (
                                  <Input
                                    type="number"
                                    step="1"
                                    className="form-control-sm d-inline w-50"
                                    value={soundPower?.operatingPoint?.airflow}
                                    disabled={isSaving || isSaved}
                                    onValueChange={(values) => onManualEntryChange(regionIndex, i, "soundPower.operatingPoint.airflow", values.floatValue)}
                                  />
                                ) : (
                                  soundPower?.operatingPoint?.airflow
                                )}{" "}
                                {typeof soundPower?.operatingPoint?.airflow === "number" || (manualEntry && "m3/h")}
                              </td>
                              <td>
                                {manualEntry && !(v instanceof SoundPowerCorrection) && i <= 2 ? (
                                  <Input
                                    type="number"
                                    step="1"
                                    className="form-control-sm d-inline w-50"
                                    value={soundPower?.operatingPoint?.speed}
                                    disabled={isSaving || isSaved}
                                    onValueChange={(values) => onManualEntryChange(regionIndex, i, "soundPower.operatingPoint.speed", values.floatValue)}
                                  />
                                ) : (
                                  soundPower?.operatingPoint?.speed
                                )}{" "}
                                {typeof soundPower?.operatingPoint?.speed === "number" || (manualEntry && "rpm")}
                              </td>
                              {frequencies.map((f, index) => (
                                <td key={index}>
                                  {manualEntry && !(v instanceof SoundPowerCorrection) && i <= 2 ? (
                                    <Input
                                      type="number"
                                      step="1"
                                      className="form-control-sm d-inline w-50"
                                      value={soundPower?.sampleAt(f)?.soundLevel?.value}
                                      disabled={isSaving || isSaved}
                                      onValueChange={(values) => onManualEntrySampleChange(regionIndex, i, `soundPower.samples[${index}].soundLevel.value`, values.floatValue)}
                                    />
                                  ) : (
                                    <FormattedNumber value={soundPower?.sampleAt(f)?.soundLevel?.value} options={decibelNumberOptions} />
                                  )}
                                </td>
                              ))}
                              <td>
                                <FormattedNumber value={soundPower?.lwA?.value} options={decibelNumberOptions} />
                              </td>
                              <td>
                                <FormattedNumber value={soundPower?.lpA?.value} options={decibelNumberOptions} />
                              </td>

                              <td>
                                {v instanceof SoundPowerTest && <FormattedNumber value={r.results[i].lpA?.value} options={decibelNumberOptions} />}
                              </td>
                            </tr>
                          </>
                        );
                      })
                    )}
                  </tbody>
                </Table>
              </div>

              {/* <SubmitButton
                className="btn btn-success float-right"
                onClick={handleSaveClick}
                submitting={isSaving ? 1 : 0}
                submitted={isSaved ? 1 : 0}
                disabled={!isValid || isSaved || isSaving}
              /> */}
            </>
          ) : (
            <div className="text-center">
              <i className="fas fa-info-circle fa-4x text-info mb-2"></i>
              <h2>Please select a modelo curva to begin.</h2>
            </div>
          )}
        </CardBody>

        <CardFooter>
          <div className="d-flex justify-content-end px-1">
            {isSaved && (
              <Button className="btn-outline-dark" onClick={(_) => setIsGeneratorOpen(true)}>
                <span>
                  <i className="fas fa-calculator"></i>{" "}
                  <FormattedMessage id="curves_generator.generate_dependent_acoustic_data" defaultMessage="Generate dependent acoustic data" />
                </span>
              </Button>
            )}
            <SubmitButton
              className="btn btn-outline-primary float-right"
              onClick={handleSaveClick}
              submitting={isSaving ? 1 : 0}
              submitted={isSaved ? 1 : 0}
              disabled={!isValid || isSaved || isSaving}
            />
          </div>
        </CardFooter>
      </Card>

      <ListerTableSelectionModal
        title={intl.formatMessage({ id: "app.select_a_curve", defaultMessage: "Select a ModeloCurva" })}
        columns={[
          { Header: intl.formatMessage({ id: "app.curve", defaultMessage: "Curve" }), accessor: "name" },
          { Header: intl.formatMessage({ id: "app.curve_group", defaultMessage: "Curve group" }), accessor: "group" },
          { Header: intl.formatMessage({ id: "app.product", defaultMessage: "Product" }), accessor: "product" },
          { Header: intl.formatMessage({ id: "app.curve_series", defaultMessage: "Curve series" }), accessor: "curveSeries" },
        ]}
        isOpen={curveModalOpen}
        toggle={toggleCurveModal}
        lister={curveLister}
        onSelect={handleCurveSelection}
      />

      <ListerTableSelectionModal
        title={intl.formatMessage({ id: "acoustic_aggregate.select_acoustic_test", defaultMessage: "Select an acoustic test" })}
        columns={[
          { Header: intl.formatMessage({ id: "tests.lab_name", defaultMessage: "Lab name" }), accessor: "laboratoryName" },
          { Header: intl.formatMessage({ id: "tests.lab_test_number", defaultMessage: "Lab test number" }), accessor: "testNumber" },
          { Header: intl.formatMessage({ id: "tests.request_number", defaultMessage: "Request number" }), accessor: "testRequestNumber" },
          { Header: intl.formatMessage({ id: "tests.brand", defaultMessage: "Brand" }), accessor: "brandName" },
          { Header: intl.formatMessage({ id: "tests.model", defaultMessage: "Model" }), accessor: "productModelName" },
          { Header: intl.formatMessage({ id: "tests.project_name", defaultMessage: "Project name" }), accessor: "projectName" },
        ]}
        isOpen={acousticTestModalOpen}
        prefilter={acousticPrefilter}
        toggle={toggleAcousticTestModal}
        lister={acousticTestLister}
        onSelect={handleAcousticTestSelection}
      />

      <DependentAcousticTestGeneratorModal
        isOpen={isGeneratorOpen}
        toggle={toggleGeneratorOpen}
        onChange={handleDependentTestChange}
        onSubmit={handleDependentTestSubmit}
        dependent={generatedDependent}
        alert={generatorAlert}
      />
    </>
  );
}

export default AcousticEntry;
