import React from "react";
import { ValidationForm } from "react-bootstrap4-form-validation";
import { FormattedMessage } from "react-intl";
import { Button, Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import CustomList from "./CustomList";

function RegulationVoltagesStep({ next, prev, availableVoltages, voltages, setVoltages, customVoltages, setCustomVoltages }) {
  return (
    <ValidationForm onSubmit={next}>
      <Card className="mt-2">
        <CardHeader>
          <FormattedMessage id="product_wizard.regulation_voltages" defaultMessage="Regulation Voltages" />
        </CardHeader>
        <CardBody>
          <p>
            <FormattedMessage id="product_wizard.choose_group_voltages" defaultMessage="Choose the group of voltages that you use to distinguish your curves:" />
          </p>
          <div style={{ display: "inline-block", minWidth: 500 }}>
            <CustomList
              list={availableVoltages}
              values={voltages}
              setValues={setVoltages}
              customValues={customVoltages}
              setCustomValues={setCustomVoltages}
              addButtonText="product_wizard.voltages_personalized"
              examplePlaceholder="Example: 230V"
            ></CustomList>
          </div>
        </CardBody>
        <CardFooter>
          <Button className="btn btn-outline-dark float-right">
            <FormattedMessage id="app.next" defaultMessage="Next" />
          </Button>
          <Button className="btn btn-outline-danger" onClick={prev}>
            <i className="fas fa-arrow-left"></i> <FormattedMessage id="app.back" defaultMessage="Back" />
          </Button>
        </CardFooter>
      </Card>
    </ValidationForm>
  );
}

export default RegulationVoltagesStep;
